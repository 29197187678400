import { useState, useEffect, useCallback } from "react";
import { useGetPostDetailApiCall } from "../apiCalls/ApiCalls";
import { Post } from "../utils/Types";

export const usePost = (id?: string) => {
	if (id === undefined) {
		return [undefined, false] as const;
	}
	const apiCall = useGetPostDetailApiCall();
	const [post, setPost] = useState<Post>();
	const load = useCallback(() => {
		apiCall.sendAsync(undefined, { id: id });
	}, [id]);
	useEffect(() => {
		load();
	}, [id]);
	useEffect(() => {
		if (!apiCall.loading) {
			setPost(apiCall.data);
		}
	}, [apiCall.loading]);
	return [post, apiCall.loading] as const;
};
