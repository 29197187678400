import { useState, useEffect, useCallback } from "react";
import { useChildrenApiCall } from "../apiCalls/ApiCalls";
import { ChildInfo } from "../utils/Types";

export const useChildren = (id: string, isWelcome?: boolean) => {
	const childrenApiCall = useChildrenApiCall();
	const [children, setChildren] = useState<ChildInfo[]>([]);
	const getNewChild = useGetNewChild(id);
	useEffect(() => {
		childrenApiCall.sendAsync(undefined, { id: id });
	}, []);
	useEffect(() => {
		if (!childrenApiCall.loading && childrenApiCall.ok) {
			const data = childrenApiCall.data ?? [];
			if (data.length === 0 && isWelcome) {
				data.push(getNewChild());
			}

			setChildren(data);
		}
	}, [childrenApiCall.loading]);
	const addChild = useCallback(() => {
		const newChildren: ChildInfo[] = children.concat([getNewChild()]);
		setChildren(newChildren);
	}, [children]);
	return [children, addChild] as const;
};

export const useGetNewChild = (id: string) =>
	useCallback(
		(): ChildInfo => ({
			id: 0,
			name: "",
			yearOfEntry: undefined,
			className: "",
			interests: [],
			parentId: id,
		}),
		[]
	);
