const Footer = () => (
	<footer>
		<div className="footer">
			<div className="copyright">©{new Date().getFullYear()} LabNet</div>
			<a
				href="https://bizontek.cz"
				className="author"
				target="_blank"
				rel="noreferrer"
			>
				Web máme od&nbsp;<span className="footer_brand">BizonTek</span>
			</a>
		</div>
	</footer>
);

export default Footer;
