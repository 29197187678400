import {
	createContext,
	Dispatch,
	FC,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useContext,
	useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { SearchQuery } from "../utils/Types";

type SearchQueryState = [
	SearchQuery | undefined,
	Dispatch<SetStateAction<SearchQuery | undefined>>
];
const SearchQueryContext = createContext<SearchQueryState>(undefined as never);
const convertToQueryString = (sq: SearchQuery): string => {
	const segments: string[] = [];
	if (sq.searchString?.length) {
		segments.push(`searchString=${sq.searchString}`);
	}
	if (sq.categories?.length) {
		segments.push(`cat=${sq.categories.join(",")}`);
	}
	return segments.join("&");
};

const getSearchQueryFromUrl = (): SearchQuery | undefined => {
	const [searchParams] = useSearchParams();
	return {
		searchString: searchParams.get("searchString") ?? "",
		take: 0,
		skip: 0,
		categories:
			searchParams
				.get("cat")
				?.split(",")
				.map((x) => +x) ?? [],
		hasChildren: false,
		classes: [],
	};
};

export const SearchQueryProvider: FC<PropsWithChildren> = ({ children }) => {
	const SearchQueryState = useState(getSearchQueryFromUrl());
	return (
		<SearchQueryContext.Provider value={SearchQueryState}>
			{children}
		</SearchQueryContext.Provider>
	);
};

export const getClearSearchQuery = (): SearchQuery => ({
	searchString: "",
	take: 0,
	skip: 0,
	categories: [],
	hasChildren: false,
	classes: [],
});

const useSearchQuery = () => {
	const [, setSearchParams] = useSearchParams();
	const context = useContext(SearchQueryContext);

	const hideActiveFilters = useCallback(() => {
		const filterElements = document.getElementsByClassName("filter");
		for (let i = 0; i < filterElements.length; i++) {
			const filterElement = filterElements[i];
			if (filterElement?.classList.contains("active")) {
				filterElement.classList.remove("active");
			}
		}
	}, []);

	const setSearchQuery = (sq: SearchQuery) => {
		setSearchParams(convertToQueryString(sq));
		context[1](sq);
	};
	const onSubmit = useCallback((values: SearchQuery) => {
		values.searchString = values.searchString ?? "";
		values.categories = values.categories ?? [];
		hideActiveFilters();
		setSearchQuery(values);
	}, []);

	return [context[0], onSubmit] as const;
};

export default useSearchQuery;
