import { FC } from "react";

import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import { PostInfo } from "../utils/Types";
import FileInput from "./components/FileInput";
import SelectInput from "./components/SelectInput";
import arrayMutators from "final-form-arrays";
import { usePostForm } from "../hooks/usePostForm";
import HiddenInput from "./components/HiddenInput";

type Props = {
	defaultValues?: PostInfo;
	loading?: boolean;
	isEdit?: boolean;
};

const PostForm: FC<Props> = ({ defaultValues, loading, isEdit }) => {
	const [onSubmit, categories, img, focusOnErrors] = usePostForm(
		defaultValues,
		isEdit
	);
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={defaultValues}
			mutators={{
				...arrayMutators,
				setValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			decorators={[focusOnErrors]}
			render={({ handleSubmit, form }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-group">
							<div className="container-box-container">
								<div className="container-box container-box-main">
									<div className="flex-space-between">
										<div className="h2"></div>
										{loading && (
											<span>
												<i className="fas fa-spinner fa-lg fa-spin"></i>
											</span>
										)}
									</div>
									<HiddenInput name="userId" />
									<TextInput
										name="title"
										label="Nadpis"
										isRequired
										validate={required}
									></TextInput>
									<SelectInput
										name="categoryId"
										label="Obor"
										placeholder="Vyberte"
										options={categories}
									></SelectInput>
									<SelectInput
										name="isOfferStr"
										label="Typ"
										placeholder="Vyberte"
										validate={required}
										options={[
											{ label: "Sháním", value: "False" },
											{ label: "Nabízím", value: "True" },
										]}
									></SelectInput>
									<FileInput
										name="imageFile"
										label="Nahrajte profilovou fotku"
										defaultPreview={img}
									/>
									<TextInput
										name="description"
										label="Podrobnosti"
										isRequired
										component="textarea"
										validate={required}
										rows={5}
									></TextInput>
								</div>
							</div>

							<div className="flex-space-between w-100">
								<button
									type="button"
									className="btn btn-secondary btn-big cursor-pointer"
									onClick={() => {
										form.reset();
										history.back();
									}}
								>
									Zrušit
								</button>
								<button
									type="submit"
									className="btn btn-primary btn-big cursor-pointer"
								>
									Uložit
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default PostForm;
