import { Routes, Route } from "react-router-dom";
import { PageUrls } from "../../constants/PageUrls";

import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import Categories from "../../pages/Categories";
import Children from "../../pages/Children";
import CreateUser from "../../pages/CreateUser";
import ForgottenPassword from "../../pages/ForgottenPassword";
import ForgottenPasswordReset from "../../pages/ForgottenPasswordReset";
import Login from "../../pages/Login";
import NotFound from "../../pages/NotFound";
import Profile from "../../pages/Profile";
import Search from "../../pages/Search";
import Security from "../../pages/Security";
import Support from "../../pages/Support";
import UserPage from "../../pages/UserPage";
import Users from "../../pages/Users";
import Welcome from "../../pages/Welcome";
import WelcomeRedirect from "../welcome/WelcomeRedirect";
import EditPost from "../../pages/EditPost";
import CreatePost from "../../pages/CreatePost";
import Board from "../../pages/Board";

const RouteGroup = (page: JSX.Element, urls: string[]) => {
	return urls.map((x, y) => <Route key={y} path={x} element={page} />);
};

const Routing = () => {
	const [user] = useLoggedInUser();
	return (
		<Routes>
			{/* unauthenticated user */}
			{!user.authenticated && RouteGroup(<Login />, PageUrls.LOGIN)}
			{!user.authenticated &&
				RouteGroup(<ForgottenPassword />, PageUrls.FORGOTTEN_PASSWORD)}
			{!user.authenticated &&
				RouteGroup(
					<ForgottenPasswordReset />,
					PageUrls.FORGOTTEN_PASSWORD_RESET
				)}
			{!user.authenticated && <Route path="*" element={<Login />} />}

			{/* authenticated user */}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<Search />, PageUrls.SEARCH)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<UserPage />, PageUrls.USER_PAGE)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<Profile />, PageUrls.PROFILE)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<Security />, PageUrls.SECURITY)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<Children />, PageUrls.CHILDREN)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<CreatePost />, PageUrls.NEW_POST)}
			{user.authenticated &&
				user.isConfirmed &&
				RouteGroup(<EditPost />, PageUrls.EDIT_POST)}
			{/* admin only */}
			{user.authenticated &&
				user.isConfirmed &&
				user.isAdmin &&
				RouteGroup(<Users />, PageUrls.USERS)}
			{user.authenticated &&
				user.isConfirmed &&
				user.isAdmin &&
				RouteGroup(<Categories />, PageUrls.CATEGORIES)}
			{user.authenticated &&
				user.isConfirmed &&
				user.isAdmin &&
				RouteGroup(<Board />, PageUrls.POSTS)}
			{/* {user && user.isAdmin && RouteGroup(<Search />, PageUrls.POSTS)} */}
			{user.authenticated &&
				user.isConfirmed &&
				user.isAdmin &&
				RouteGroup(<CreateUser />, PageUrls.USER_CREATE)}

			{/* parent only */}

			{user.authenticated &&
				user.isConfirmed &&
				!user.isAdmin &&
				RouteGroup(<Support />, PageUrls.SUPPORT)}

			{/* parent unconfirmed */}
			{user.authenticated &&
				!user.isConfirmed &&
				RouteGroup(<Welcome />, PageUrls.WELCOME)}
			{user.authenticated && !user.isConfirmed && (
				<Route path="*" element={<WelcomeRedirect />} />
			)}

			{user.authenticated && user.isConfirmed && (
				<Route path="*" element={<NotFound />} />
			)}
		</Routes>
	);
};

export default Routing;
