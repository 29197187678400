import { FieldValidator } from "final-form";
import { FC, useCallback, useEffect, useState } from "react";
import { Field, useField } from "react-final-form";
import $ from "jquery";

type Props = {
	name: string;
	validate?: FieldValidator<string>;
	label?: string;
	defaultPreview?: string;
};

const FileInput: FC<Props> = ({ name, validate, label, defaultPreview }) => {
	const { meta } = useField(name, {
		subscription: {
			value: true,
			touched: true,
			error: true,
			submitError: true,
		},
		type: "file",
		validate,
	});
	const openUploadModal = useCallback((e: React.MouseEvent) => {
		if (!$(e.target).hasClass("preview-image__delete")) {
			$("#file-input").trigger("click");
		}
	}, []);
	const removeImage = useCallback(() => {
		setPreview(undefined);
	}, []);
	const [preview, setPreview] = useState<string>();
	const onChangeCustom = useCallback(
		(target: EventTarget & HTMLInputElement) => {
			if (target.files === null || target.files.length === 0) {
				setPreview(undefined);
			} else {
				const file = target.files[0];
				if (file.size > 15728640) {
					//15MB
					alert("Soubor je příliš velký");
					setPreview(undefined);
				} else {
					const objectUrl = URL.createObjectURL(file);
					setPreview(objectUrl);
				}
			}
		},
		[]
	);
	useEffect(() => {
		setPreview(defaultPreview);
	}, [defaultPreview]);
	const hasError = (meta.touched && meta.error) || meta.submitError;
	return (
		<>
			<div className="form-control">
				<div
					className={`container-dashed ${
						hasError ? "has-error" : ""
					}`}
					onClick={openUploadModal}
				>
					{preview && preview !== "" ? (
						<div className="preview-image">
							<button
								type="button"
								className="btn btn-sm btn-delete preview-image__delete"
								onClick={removeImage}
							>
								Odstranit
							</button>
							<img src={preview} />
						</div>
					) : (
						<>
							<i className="upload-icon fa-solid fa-plus fa-lg"></i>
							<span className="upload-text">{label}</span>
						</>
					)}
				</div>
				<Field<File> name={name}>
					{({ input: { value, onChange, ...input } }) => {
						value;
						return (
							<input
								{...input}
								type="file"
								onChange={({ target }) => {
									onChangeCustom(target);
									if (
										target.files !== null &&
										target.files.length > 0
									) {
										onChange(target.files[0]);
									}
								}}
								className="d-none"
								id="file-input"
								accept="image/png, image/gif, image/jpeg"
							/>
						);
					}}
				</Field>

				<span className="error-message">
					{hasError && (
						<span>
							{meta.error}
							{!meta.error && meta.submitError}
						</span>
					)}
				</span>
			</div>
		</>
	);
};

export default FileInput;
