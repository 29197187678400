import SessionManager from "../utils/SessionManager";

const headers = (exludeCT: boolean) => {
	const headers = new Headers({
		Authorization: `Bearer ${SessionManager.getToken()}`,
	});
	if (!exludeCT) {
		headers.append("Content-Type", "application/json");
	} else {
		//headers.append('Content-Type', 'application/x-www-form-urlencoded');
	}
	return headers;
};

export const Api = async (endpoint: string, method: string, data?: object) =>
	await fetch(`${process.env.REACT_APP_API_URL}/${trimEndpoint(endpoint)}`, {
		method,
		headers: headers(data instanceof FormData),
		body: data
			? data instanceof FormData
				? data
				: JSON.stringify(data)
			: undefined,
	}).catch(() => console.log("Connection failed"));

const trimEndpoint = (endpoint: string) => {
	while (endpoint.charAt(0) === "/") {
		endpoint = endpoint.substring(1, endpoint.length);
	}
	return endpoint;
};

export class UnauthorizedError extends Error {
	constructor(msg: string) {
		super(msg);
	}
}

export class InternalError extends Error {
	constructor(msg: string) {
		super(msg);
	}
}

export class BadRequestError extends Error {
	constructor(msg: string) {
		super(msg);
	}
}
