import { FieldValidator } from "final-form";
import { Field, useField } from "react-final-form";

type Props = {
	name: string;
	validate?: FieldValidator<string>;
	id?: string;
	label?: string;
};

const CheckboxInput = ({ name, validate, label, id }: Props) => {
	const { input } = useField(name, {
		subscription: {
			value: true,
			touched: true,
			error: true,
		},
		type: "checkbox",
		validate,
	});
	return (
		<>
			<div className="form-control checkbox">
				<div className="form-control-checkbox">
					<Field
						type="checkbox"
						id={id}
						component="input"
						validate={validate}
						{...input}
					/>
				</div>
				<label htmlFor={id}>{label}</label>
			</div>
		</>
	);
};

export default CheckboxInput;
