import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteUserApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import { FlashMessageSeverity, useAddFlashMessage } from "./useFlashMessage";
import { PageHelper } from "../utils/PageHelper";

export const useUserDelete = (id: string) => {
	const deleteApiCall = useDeleteUserApiCall();
	const flashMessage = useAddFlashMessage();
	const navigate = useNavigate();
	const onDelete = useCallback(async () => {
		const res = await deleteApiCall.send(undefined, { id: id });
		if (res?.ok) {
			flashMessage("Uživatel byl smazán");
			navigate(PageUrls.USERS[0]);
		} else {
			flashMessage(
				"Uživatel nemohl být smazán",
				FlashMessageSeverity.WARNING
			);
			PageHelper.scrollUp();
		}
	}, []);
	return onDelete;
};
