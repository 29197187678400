import { useParams } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import PostForm from "../forms/PostForm";
import { useLoggedInUser } from "../hooks/useLoggedInUser";

const CreatePost = () => {
	const [user] = useLoggedInUser();
	const userId = useParams()["id"] ?? user.id;
	const title = "Nový příspěvek";
	usePageTitle(title);
	return (
		<>
			<div className="h1">{title}</div>
			<PostForm
				defaultValues={{ userId: userId }}
				loading={false}
				isEdit={false}
			/>
		</>
	);
};

export default CreatePost;
