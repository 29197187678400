import { Field, useField } from "react-final-form";

const SearchBar = () => {
	const { input } = useField("searchString", {
		subscription: {
			value: true,
		},
		type: "text",
	});
	return (
		<div className="form-control">
			<div className="form-control-input">
				<Field component="input" placeholder="Hledat" {...input} />

				<span id="search-bar-loading">
					<i className="fas fa-spinner fa-lg fa-spin"></i>
				</span>

				<button
					type="submit"
					className="btn btn-void btn-min cursor-pointer"
				>
					<i className="fa-solid fa-magnifying-glass fa-lg"></i>
				</button>
			</div>
		</div>
	);
};

export default SearchBar;
