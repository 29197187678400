export class PageUrls {
	static LOGIN = ["/login", "/prihlaseni"];
	static FORGOTTEN_PASSWORD = ["/zapomenute-heslo"];
	static FORGOTTEN_PASSWORD_RESET = ["/zmena-hesla"];

	static SEARCH = ["/", "/vyhledavani"];
	static USER_PAGE = ["/stranka/:id"];

	static USER_PAGE_LINK = (id: string) =>
		this.USER_PAGE[0].replace(":id", id);

	static PROFILE = ["/osobni-udaje"];
	static CHILDREN = ["/vase-dite"];
	static SECURITY = ["/zabezpeceni"];
	static SUPPORT = ["/podpora"];
	static WELCOME = ["/vitejte-v-labnetu"];

	static USERS = ["/uzivatele"];
	static USER_CREATE = ["/novy-uzivatel"];

	static POSTS = ["/nastenka"];
	static CATEGORIES = ["/obory"];

	static NEW_POST = ["/novy-prispevek", "/novy-prispevek/:id"];
	static EDIT_POST = ["/prispevek/:id"];
	static POST_NEW_LINK = (id: string) => this.NEW_POST[1].replace(":id", id);
	static POST_EDIT_LINK = (id: string) =>
		this.EDIT_POST[0].replace(":id", id);
}
