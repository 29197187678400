import { useCallback, useState } from "react";
import PasswordResetForm from "../forms/PasswordResetForm";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import usePageTitle from "../hooks/usePageTitle";

enum Mode {
	VIEW,
	EDIT,
}

const Security = () => {
	usePageTitle("Zabezpečení");
	const [user] = useLoggedInUser();
	const [mode, setMode] = useState<Mode>(Mode.VIEW);
	const returnAction = useCallback(() => setMode(Mode.VIEW), []);
	return (
		<>
			<div className="h1">Zabezpečení</div>
			<div className="container-group">
				<div className="container-box-container">
					<div className="container-box container-box-dark">
						<div className="h2">Uživatelské jméno</div>
						<span className="text">{user.email}</span>
					</div>
				</div>
				{mode === Mode.VIEW && (
					<div className="container-box-container">
						<div className="container-box container-box-dark">
							<div className="h2">Heslo</div>
							<div className="flex-space-between">
								<button
									className="btn btn-secondary cursor-pointer"
									type="button"
									onClick={() => setMode(Mode.EDIT)}
								>
									<i className="fa-solid fa-pen-to-square"></i>
									Upravit
								</button>
							</div>
						</div>
					</div>
				)}
				{mode === Mode.EDIT && (
					<PasswordResetForm
						afterSubmit={returnAction}
						onBack={returnAction}
					/>
				)}
			</div>
		</>
	);
};

export default Security;
