import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageUrls } from "../../constants/PageUrls";

const WelcomeRedirect = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(PageUrls.WELCOME[0]);
	});
	return <></>;
};

export default WelcomeRedirect;
