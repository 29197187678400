import { FC } from "react";
import { useChildren } from "../../hooks/useChildren";
import ChildComponent from "./ChildComponent";

type Props = {
	parentId: string;
	editEnabled?: boolean;
	isWelcome?: boolean;
};

const ChildrenList: FC<Props> = ({ parentId, editEnabled = false }) => {
	const [children, addChild] = useChildren(parentId, false);
	return (
		<>
			{children?.map((x, key) => (
				<ChildComponent
					childInfo={x}
					key={key}
					editEnabled={editEnabled}
				/>
			))}

			{editEnabled && (
				<div
					className="container-box-container"
					onClick={() => addChild()}
				>
					<div className="container-dashed">
						<i className="upload-icon fa-solid fa-plus fa-lg"></i>
						<span className="upload-text">Přidat dítě</span>
					</div>
				</div>
			)}
		</>
	);
};

export default ChildrenList;
