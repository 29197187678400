import { FC, useCallback } from "react";
import { Post } from "../../utils/Types";
import { PageHelper } from "../../utils/PageHelper";
import { useImage } from "../../hooks/useImage";
import { Link, useNavigate } from "react-router-dom";
import { PageUrls } from "../../constants/PageUrls";
//import Link from "../common/Link";
type Props = {
	post: Post;
	isPreview?: boolean;
	editEnabled?: boolean;
	onDelete?: () => void;
	showAuthor?: boolean;
};

const PostCard: FC<Props> = ({
	post,
	isPreview,
	editEnabled,
	onDelete,
	showAuthor,
}) => {
	const img = useImage(post.imageId, 500);
	const navigate = useNavigate();
	const redirectToUserPage = useCallback(() => {
		if (isPreview) {
			navigate(PageUrls.USER_PAGE_LINK(post.userId ?? "0"));
			PageHelper.scrollUp();
		}
	}, []);
	return (
		<div className="board-note-container">
			<div
				className={`board-note ${isPreview ? "cursor-pointer" : ""}`}
				onClick={redirectToUserPage}
			>
				{showAuthor && <span className="author">{post.name}</span>}
				<span className="h2 title">{post.title}</span>
				{post.createdDate && (
					<span className="date text-board-date">
						{post.createdDate}
					</span>
				)}
				{post.category && (
					<span className="field text-board-date">
						{post.category}
					</span>
				)}

				<span
					className={`badge ${
						post.isOffer ? "offering" : "badge-secondary looking"
					}`}
				></span>
				<span className="text">
					{isPreview
						? PageHelper.getShortDescription(post.description)
						: post.description}
				</span>
				{(post.imageId || (editEnabled && post.id)) && (
					<div className="board-note-footer">
						{post.imageId && (
							<div className="image">
								<img src={img} />
							</div>
						)}

						<div className="board-note-footer-right">
							{editEnabled && post.id && (
								<>
									<button
										className="btn btn-void  btn-sm error text-button-sm cursor-pointer"
										type="button"
										onClick={onDelete}
									>
										Smazat
									</button>
									<Link
										to={PageUrls.POST_EDIT_LINK(post.id)}
										className="btn btn-secondary btn-sm text-button-sm"
										type="button"
									>
										<i className="fa-solid fa-pen-to-square"></i>
										Upravit
									</Link>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PostCard;
