import { FC } from "react";
import { UserInfo } from "../../utils/Types";
import BadgesContainer from "../common/BadgesContainer";
import { PageHelper } from "../../utils/PageHelper";

type Props = {
	userInfo?: UserInfo;
	editEnabled?: boolean;
	onEdit?: () => void;
};

const UserInfoComponent: FC<Props> = ({
	userInfo,
	editEnabled = false,
	onEdit,
}) => {
	return (
		<div className="container-group">
			<div className="container-box-container">
				<div
					className={`container-box ${
						editEnabled
							? "container-box-dark"
							: "container-box-main"
					}`}
				>
					<div className="info-grid">
						{userInfo?.contactEmail && (
							<>
								<div className="text-button">E-mail:</div>
								<a
									href={`mailto:${userInfo.contactEmail}`}
									className="text"
								>
									{userInfo.contactEmail}
								</a>
							</>
						)}
						{userInfo?.phoneNumber && (
							<>
								<div className="text-button">Telefon:</div>
								<a
									href={`tel:${userInfo.phoneNumber}`}
									className="text"
								>
									{userInfo.phoneNumber}
								</a>
							</>
						)}
						{userInfo?.linkedInUrl && (
							<>
								<div className="text-button">Linked-in:</div>
								<a
									href={`${PageHelper.getUrl(
										userInfo.linkedInUrl
									)}`}
									target="_blank"
									className="text"
									rel="noreferrer"
								>
									{userInfo.linkedInUrl}
								</a>
							</>
						)}
						{userInfo?.residence && (
							<>
								<div className="text-button">Bydliště:</div>
								<div className="text">{userInfo.residence}</div>
							</>
						)}

						{(userInfo?.profession ||
							userInfo?.category ||
							userInfo?.employer ||
							userInfo?.webUrl) && (
							<>
								<div className="row-space"></div>
								<div></div>
								{userInfo?.profession && (
									<>
										<div className="text-button">
											Profese:
										</div>
										<div className="text">
											{userInfo.profession}
										</div>
									</>
								)}
								{userInfo?.category && (
									<>
										<div className="text-button">Obor:</div>
										<div className="text">
											{userInfo.category}
										</div>
									</>
								)}
								{userInfo?.employer && (
									<>
										<div className="text-button">
											Zaměstnavatel:
										</div>
										<div className="text">
											{userInfo.employer}
										</div>
									</>
								)}
								{userInfo?.webUrl && (
									<>
										<div className="text-button">Web:</div>
										<a
											href={PageHelper.getUrl(
												userInfo.webUrl
											)}
											target="_blank"
											className="text"
											rel="noreferrer"
										>
											{userInfo.webUrl}
										</a>
									</>
								)}
							</>
						)}
						{(!!userInfo?.interests?.length ||
							!!userInfo?.offering?.length) && (
							<>
								<div className="row-space"></div>
								<div></div>
								{!!userInfo?.interests?.length && (
									<>
										<div className="text-button">
											Zájmy:
										</div>
										<BadgesContainer
											badges={userInfo.interests}
										/>
									</>
								)}
								{!!userInfo?.offering?.length && (
									<>
										<div className="text-button">
											Co nabízím:
										</div>
										<BadgesContainer
											badges={userInfo.offering}
										/>
									</>
								)}
							</>
						)}
					</div>
					{editEnabled && (
						<div className="flex-end">
							<button
								className="btn btn-secondary text-button-sm cursor-pointer"
								type="button"
								onClick={onEdit}
							>
								<i className="fa-solid fa-pen-to-square"></i>
								Upravit
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserInfoComponent;
