import BoardComponent from "../components/board/BoardComponent";
import SearchComponent from "../components/search/SearchComponent";
import usePageTitle from "../hooks/usePageTitle";
import { SearchQueryProvider } from "../hooks/useSearchQuery";

const Search = () => {
	usePageTitle("Vyhledávání");
	return (
		<>
			<SearchQueryProvider>
				<SearchComponent />
			</SearchQueryProvider>
			<BoardComponent />
		</>
	);
};

export default Search;
