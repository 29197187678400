export class ApiEndpoints {
	static LOGIN = "/api/authentication/login";
	static FORGOTTEN_PASSWORD = "/api/forgottenPassword/forgottenPassword";
	static FORGOTTEN_PASSWORD_RESET = "/api/forgottenPassword/resetPassword";
	static IS_HASH_VALID = "/api/forgottenPassword/isHashValid";
	static PASSWORD_RESET = "/api/resetPassword/resetPassword";
	static CHILD_DELETE = "/api/child/delete/:id";
	static SEARCH = "/api/search/search";
	static USER_PAGE_GET = "/api/userPage/get/:id";
	static USER_PAGE_PUT = "/api/userPage/put/:id";
	static CHILD_PUT = "/api/child/put/:id";
	static CHILD_LIST_PUT = "/api/child/putlist";
	static CHILD_POST = "/api/child/post/:id";
	static USER_DELETE = "/api/user/delete/:id";
	static USER_CONFIRM = "/api/user/confirm/:id";
	static CHILDREN_GET = "/api/child/get/:id";
	static CATEGORY_GET = "/api/category/get";
	static USERS_GET = "/api/user/get";
	static USER_POST = "/api/user/post";
	static CATEGORIES_POST = "/api/category/post";
	static IMAGE = "/api/file/get/:id";
	static POST_DELETE = "/api/post/delete/:id";
	static POST_PUT = "/api/post/put/:id";
	static POST_POST = "/api/post/post/:id";
	static POSTS_GET = "/api/post/get";
	static POSTS_GET_USERS = "/api/post/getUsers/:id";
	static POSTS_GET_DETAIL = "/api/post/getDetail/:id";
}
