import { useState, useEffect } from "react";
import { useGetCategories } from "../apiCalls/ApiCalls";
import { Category } from "../utils/Types";
import usePageTitle from "./usePageTitle";

export const useCategoriesManage = () => {
	usePageTitle("Obory");
	const [customCategories, setCustomCategories] = useState<Category[]>([]);
	const [baseCategories, setBaseCategories] = useState<Category[]>([]);
	const getCategoriesApiCall = useGetCategories(true);
	useEffect(() => {
		if (!getCategoriesApiCall.loading && getCategoriesApiCall.ok) {
			const categories = getCategoriesApiCall?.data ?? [];
			if (baseCategories.length === 0) {
				setBaseCategories(categories.filter((x) => !!x.isBaseCategory));
			}
			setCustomCategories(categories.filter((x) => !x.isBaseCategory));
		}
	}, [getCategoriesApiCall.loading]);
	useEffect(() => {
		getCategoriesApiCall.sendAsync();
	}, []);
	return [
		customCategories,
		baseCategories,
		getCategoriesApiCall.sendAsync,
	] as const;
};
