import { getFlashMessages } from "../../hooks/useFlashMessage";
import FlashMessage from "./FlashMessage";

const FlashMessages = () => {
	const flashMessages = getFlashMessages();
	return (
		<div className="flash-message-container">
			{flashMessages.flashMessages.map((x, key) => (
				<FlashMessage settings={x} key={key} />
			))}
		</div>
	);
};

export default FlashMessages;
