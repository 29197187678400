import { useState, useEffect } from "react";
import {
	useGetPostApiCall,
	useGetUsersPostApiCall,
} from "../apiCalls/ApiCalls";
import { Post } from "../utils/Types";

export const usePosts = (id?: string) => {
	const postsApiCall = id ? useGetUsersPostApiCall() : useGetPostApiCall();
	const params = id ? { id: id } : {};

	const [posts, setPosts] = useState<Post[]>([]);
	useEffect(() => {
		postsApiCall.sendAsync(undefined, params);
	}, []);
	useEffect(() => {
		if (!postsApiCall.loading && postsApiCall.ok) {
			const data = postsApiCall.data ?? [];
			setPosts(data);
		}
	}, [postsApiCall.loading]);
	return [posts] as const;
};
