import { FC, useState } from "react";
import { Post } from "../../utils/Types";
import PostDelete from "./PostDelete";
import PostCard from "./PostCard";

type Props = {
	post: Post;
	editEnabled?: boolean;
	isPreview?: boolean;
	showAuthor?: boolean;
};

enum Mode {
	VIEW,
	DELETE,
	DELETED,
}

const PostComponent: FC<Props> = ({
	post,
	editEnabled = false,
	isPreview,
	showAuthor,
}) => {
	const [mode, setMode] = useState<Mode>(Mode.VIEW);
	return (
		<>
			{mode == Mode.VIEW && (
				<PostCard
					post={post}
					onDelete={() => {
						setMode(Mode.DELETE);
					}}
					editEnabled={editEnabled}
					isPreview={isPreview}
					showAuthor={showAuthor}
				/>
			)}
			{mode == Mode.DELETE && editEnabled && (
				<PostDelete
					onBack={() => setMode(Mode.VIEW)}
					afterDelete={() => setMode(Mode.DELETED)}
					post={post}
				/>
			)}
		</>
	);
};

export default PostComponent;
