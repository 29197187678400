import { FC } from "react";
import { ChildInfo } from "../../utils/Types";
import BadgesContainer from "../common/BadgesContainer";

type Props = {
	childInfo: ChildInfo;
	onEdit?: () => void;
	onDelete?: () => void;
	editEnabled?: boolean;
};

const ChildInfoComponent: FC<Props> = ({
	childInfo,
	onEdit,
	onDelete,
	editEnabled = false,
}) => (
	<div className="container-box-container">
		<div
			className={`container-box ${
				editEnabled ? "container-box-dark" : "container-box-main"
			}`}
		>
			<div className="h2">{childInfo.name}</div>
			<div className="info-grid">
				<div className="text-button">Rok nástupu</div>
				<div className="text">{childInfo.yearOfEntry}</div>

				<div className="text-button">Třída</div>
				<div className="text">{childInfo.className}</div>

				{childInfo.interests.length > 0 && (
					<>
						<div className="text-button">Zájmy dítěte</div>
						<BadgesContainer badges={childInfo.interests} />
					</>
				)}
			</div>
			{editEnabled && (
				<div className="flex-end pt-2">
					<button
						className="btn btn-void error text-button-sm cursor-pointer"
						type="button"
						onClick={onDelete}
					>
						Smazat
					</button>
					<button
						className="btn btn-secondary text-button-sm cursor-pointer"
						type="button"
						onClick={onEdit}
					>
						<i className="fa-solid fa-pen-to-square"></i>
						<span className="ml-4">Upravit</span>
					</button>
				</div>
			)}
		</div>
	</div>
);

export default ChildInfoComponent;
