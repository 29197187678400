import {
	createContext,
	Dispatch,
	FC,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import SessionManager from "../utils/SessionManager";
import { User } from "../utils/Types";
import { useNavigate } from "react-router-dom";
import { PageUrls } from "../constants/PageUrls";

type UserState = [User, Dispatch<SetStateAction<User>>];
const UserContext = createContext<UserState>(undefined as never);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
	const userState = useState(SessionManager.getUser());
	useEffect(() => {
		const updateState = () => userState[1](SessionManager.getUser());
		addEventListener("storage", updateState);
		return () => {
			removeEventListener("storage", updateState);
		};
	}, []);
	return (
		<UserContext.Provider value={userState}>
			{children}
		</UserContext.Provider>
	);
};

export const useLoggedInUser = () => {
	const user = useContext(UserContext)[0];
	return [user, setAuth] as const;
};

const setAuth = (newVal: User | null) => {
	if (newVal !== null) {
		SessionManager.setUserSession(newVal);
	} else {
		SessionManager.removeUserSession();
	}
	dispatchEvent(new Event("storage"));
};

export const useLogout = () => {
	const navigate = useNavigate();
	const logout = useCallback(() => {
		setAuth(null);
		navigate(PageUrls.LOGIN[0]);
	}, []);
	return logout;
};
