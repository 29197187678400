export class PageHelper {
	static handleClickScroll = (id: string) => {
		const element = document.getElementById(id);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	static getUrl = (original: string): string => {
		const res = original
			.replace("https://", "")
			.replace("http://", "")
			.trimStart()
			.trimEnd()
			.toLowerCase();
		return `https://${res}`;
	};

	static scrollUp = () => {
		this.handleClickScroll("strip");
	};

	static getShortDescription = (original?: string): string => {
		const maxLength = 120;
		if (original === undefined) {
			return "";
		}
		return original.length <= maxLength + 5
			? original
			: `${original.substring(0, maxLength)}...`;
	};
}
