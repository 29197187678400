import { useState, useEffect, useCallback } from "react";
import { useGetCategories } from "../apiCalls/ApiCalls";
import { Category } from "../utils/Types";
import { useFilter } from "./useFilter";
import useSearchQuery, { getClearSearchQuery } from "./useSearchQuery";
import $ from "jquery";

export const useFilters = (
	setValue: (fieldName: string, value: object) => void
) => {
	const [categories, setCategories] = useState<Category[]>([]);
	const [onDropdownClick, onFilterReset] = useFilter(setValue);
	const getCategoriesApiCall = useGetCategories();
	useEffect(() => {
		if (!getCategoriesApiCall.loading && getCategoriesApiCall.ok) {
			const categories = getCategoriesApiCall?.data ?? [];
			setCategories(categories);
		}
	}, [getCategoriesApiCall.loading]);
	useEffect(() => {
		getCategoriesApiCall.sendAsync();
	}, []);
	const [, setSearchQuery] = useSearchQuery();
	const onRemoveFilterClick = useCallback(() => {
		setSearchQuery(getClearSearchQuery());
	}, []);

	useEffect(() => {
		$(document).on("click", function (e) {
			if ($(e.target).closest(".filter").length === 0) {
				$(".filter").each(function () {
					if (this.classList.contains("active")) {
						this.classList.remove("active");
					}
				});
			}
		});
	}, []);

	return [
		categories,
		onDropdownClick,
		onFilterReset,
		onRemoveFilterClick,
	] as const;
};
