import { FlashMessageProvider } from "../../hooks/useFlashMessage";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import AloneLogo from "./AloneLogo";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Routing from "./Routing";

const Layout = () => {
	const [user] = useLoggedInUser();
	return (
		<>
			<div className="strip" id="strip"></div>
			{!user.authenticated && <AloneLogo />}
			<div className="container">
				<Navigation />
				<FlashMessageProvider>
					<main>
						<Routing />
					</main>
				</FlashMessageProvider>
			</div>
			{user.authenticated && <Footer />}
		</>
	);
};

export default Layout;
