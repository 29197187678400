import UserPageForm from "../forms/UserPageForm";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import usePageTitle from "../hooks/usePageTitle";
import { useUserPage } from "../hooks/useUserPage";

const Profile = () => {
	const [user] = useLoggedInUser();
	const id = user?.id ?? "";
	const [userPage, loading] = useUserPage(id);
	usePageTitle("Osobní údaje");
	return (
		<>
			<div className="h1">Upravit osobní údaje</div>
			<UserPageForm defaultValues={userPage} loading={loading} />
		</>
	);
};

export default Profile;
