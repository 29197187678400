import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForgottenPasswordResetApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import { FormHelper } from "../utils/FormHelper";
import { useAddFlashMessage } from "./useFlashMessage";

export const useForgottenPasswordResetForm = () => {
	const navigate = useNavigate();
	const loginApiCallResult = useForgottenPasswordResetApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		const res = await loginApiCallResult.send(values);
		if (res?.ok) {
			flashMessage("Heslo bylo změněno");
			navigate(`${PageUrls.LOGIN[0]}`);
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	return onSubmit;
};
