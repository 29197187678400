import createDecorator from "final-form-focus";
import { useCallback, useMemo } from "react";
import { usePostChildApiCall, usePutChildApiCall } from "../apiCalls/ApiCalls";
import { FormHelper } from "../utils/FormHelper";
import { PageHelper } from "../utils/PageHelper";
import { ChildInfo } from "../utils/Types";
import { useAddFlashMessage } from "./useFlashMessage";

type FormDataObject = ChildInfo & {
	interests_new: string;
};

export const useChildForm = (
	childInfo: ChildInfo,
	onOk: (values: object) => void
) => {
	const apiCall =
		childInfo.id === 0 ? usePostChildApiCall() : usePutChildApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		values = manageUnsetBadges(values as FormDataObject);
		const formData = values;
		const res = await apiCall.send(formData, {
			id: childInfo.id === 0 ? childInfo.parentId : childInfo.id,
		});
		if (res?.ok) {
			PageHelper.scrollUp();
			flashMessage("Změny byly uloženy");
			if (typeof res?.data === "number") {
				(values as ChildInfo).id = res.data;
			}
			onOk(values);
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	const classes = FormHelper.getClassNameOptions();
	const focusOnErrors = useMemo(() => createDecorator(), []);
	return [onSubmit, classes, focusOnErrors] as const;
};

const manageUnsetBadges = (values: FormDataObject): object => {
	const interestNew = FormHelper.clearBadgeValue(values.interests_new);
	if (interestNew) {
		values.interests.concat(interestNew);
	}
	return values;
};
