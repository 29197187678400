import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForgottenPasswordApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import { FormHelper } from "../utils/FormHelper";
import { useAddFlashMessage } from "./useFlashMessage";

export const useForgottenPasswordForm = () => {
	const navigate = useNavigate();
	const loginApiCallResult = useForgottenPasswordApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		const res = await loginApiCallResult.send(values);
		if (res?.ok) {
			flashMessage(
				"Pokud e-mail v systému existuje, zašleme Vám instrukce pro obnovení hesla."
			);
			navigate(`${PageUrls.LOGIN[0]}`);
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	return onSubmit;
};
