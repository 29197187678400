import { Form } from "react-final-form";
import useSearchQuery from "../../hooks/useSearchQuery";
import Filters from "./Filters";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import arrayMutators from "final-form-arrays";

const SearchComponent = () => {
	const [searchQuery, onSubmit] = useSearchQuery();

	return (
		<div className="container-group">
			<Form
				onSubmit={onSubmit}
				initialValues={searchQuery}
				mutators={{
					...arrayMutators,
					setValue: ([field, value], state, { changeValue }) => {
						changeValue(state, field, () => value);
					},
				}}
				render={({
					handleSubmit,
					form: {
						mutators: { setValue },
					},
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className="container-box-container">
								<div className="container-box container-box-nobox">
									<SearchBar />
									<Filters setValue={setValue} />
								</div>
							</div>
						</form>
					);
				}}
			/>
			<SearchResults />
		</div>
	);
};

export default SearchComponent;
