import { FC, useState } from "react";
import ChildForm from "../../forms/ChildForm";
import { ChildInfo } from "../../utils/Types";
import ChildDelete from "./ChildDelete";
import ChildInfoComponent from "./ChildInfo";

type Props = {
	childInfo: ChildInfo;
	editEnabled?: boolean;
};

enum Mode {
	VIEW,
	EDIT,
	DELETE,
	DELETED,
	NEW,
}

const ChildComponent: FC<Props> = ({ childInfo, editEnabled = false }) => {
	const [mode, setMode] = useState<Mode>(
		childInfo.id == 0 ? Mode.NEW : Mode.VIEW
	);
	const [child, setChild] = useState(childInfo);
	return (
		<>
			{mode == Mode.EDIT && editEnabled && (
				<ChildForm
					defaultValues={child}
					afterSubmit={() => setMode(Mode.VIEW)}
					onBack={() => setMode(Mode.VIEW)}
					setChildInfo={setChild}
				/>
			)}
			{mode == Mode.VIEW && (
				<ChildInfoComponent
					childInfo={child}
					onEdit={() => {
						setMode(Mode.EDIT);
					}}
					onDelete={() => {
						setMode(Mode.DELETE);
					}}
					editEnabled={editEnabled}
				/>
			)}
			{mode == Mode.DELETE && editEnabled && (
				<ChildDelete
					onBack={() => setMode(Mode.VIEW)}
					afterDelete={() => setMode(Mode.DELETED)}
					childInfo={child}
				/>
			)}
			{mode == Mode.NEW && editEnabled && (
				<ChildForm
					defaultValues={child}
					afterSubmit={() => setMode(Mode.VIEW)}
					onBack={() => setMode(Mode.DELETED)}
					setChildInfo={setChild}
				/>
			)}
		</>
	);
};

export default ChildComponent;
