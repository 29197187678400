import Posts from "../components/board/Posts";
import usePageTitle from "../hooks/usePageTitle";

const Board = () => {
	usePageTitle("Nástěnka");
	return (
		<>
			<div className="h1">Správa nástěnky</div>
			<Posts editEnabled={true} />
		</>
	);
};

export default Board;
