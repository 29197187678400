import { FC } from "react";
import UserPageForm from "../../forms/UserPageForm";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useUserPage } from "../../hooks/useUserPage";

type Props = {
	onNext: () => void;
	onBack: () => void;
};

const ProfileStep: FC<Props> = ({ onBack, onNext }) => {
	const [user] = useLoggedInUser();
	const id = user?.id ?? "";
	const [userPage, loading] = useUserPage(id);
	return (
		<>
			<div className="h1">Vaše osobní údaje</div>
			<UserPageForm
				defaultValues={userPage}
				loading={loading}
				onBack={onBack}
				afterSubmit={onNext}
				backLabel="Zpět"
				submitLabel="Pokračovat"
			/>
		</>
	);
};

export default ProfileStep;
