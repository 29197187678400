import { useParams } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import { usePost } from "../hooks/usePost";
import PostForm from "../forms/PostForm";

const EditPost = () => {
	const id = useParams()["id"];
	const [post, loading] = usePost(id);
	const title = "Upravit příspěvek";
	usePageTitle(title);
	return (
		<>
			<div className="h1">{title}</div>
			<PostForm defaultValues={post} loading={loading} isEdit={true} />
		</>
	);
};

export default EditPost;
