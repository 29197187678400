import { FC, useCallback, useEffect, useState } from "react";
import { FlashMessageSetting } from "../../hooks/useFlashMessage";
import "../../styles/sass/flashMessage.scss";

type Props = {
	settings: FlashMessageSetting;
};

const FlashMessage: FC<Props> = ({ settings }) => {
	const [open, setOpen] = useState(true);
	const onClose = useCallback(() => {
		setOpen(false);
	}, []);
	useEffect(() => {
		if (settings.timeout > 0) {
			setTimeout(onClose, settings.timeout);
		}
	}, []);
	return (
		<div
			className={`flash-message-box ${settings.severity} ${
				open ? "open" : ""
			}`}
		>
			<div className="flash-message">
				<span className="icon"></span>
				<div className="content">
					{settings.showCross && (
						<div
							className="cross cursor-pointer"
							onClick={onClose}
						></div>
					)}
					<div className="message">{settings.message}</div>
				</div>
			</div>
		</div>
	);
};

export default FlashMessage;
