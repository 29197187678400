import usePageTitle from "../hooks/usePageTitle";

const Support = () => {
	usePageTitle("Podpora");
	return (
		<>
			<div className="h1">Podpora</div>
			<div className="container-box-container">
				<div className="container-box container-box-nobox">
					<p>
						Děkujeme za registraci do webové databáze,
						prostřednictvím které můžete sdílet s ostatními rodiči
						čím se zabýváte, co můžete nabídnout nebo s čím umíte
						poradit.
					</p>
					<p>
						Po vyplnění vašich údajů věnujte pozornost zejména
						vyplnění vaši zájmů a know-how. Právě prostřednictvím
						těchto klíčových slov vás pak budou moci najít a
						kontaktovat další rodiče. K vašemu profilu si můžete
						také přidat vaše děti, včetně jejich zájmů a koníčků.
						Stejný způsobem pak je možné díky vyhledávači najít děti
						se stejnými koníčky a propojit se s nimi. Další
						funkcionalitou je nástěnka, na které si můžete vystavit
						nabídku čehokoliv, co byste chtěli nabídnout ostatním
						rodičům.
					</p>
				</div>
			</div>
		</>
	);
};

export default Support;
