import { useCallback } from "react";
import $ from "jquery";

const PasswordEyeToggle = () => {
	const onClick = useCallback((e: React.MouseEvent) => {
		const input = $(e.currentTarget)
			.parent()
			.parent()
			.find("input")
			.first();

		if (input.attr("type") === "password") {
			input.attr("type", "text");
		} else {
			input.attr("type", "password");
		}
	}, []);
	return (
		<button
			type="button"
			className="password-switch btn btn-void btn-min"
			onClick={(e) => onClick(e)}
		>
			<i className="fa-regular fa-eye-slash"></i>
		</button>
	);
};

export default PasswordEyeToggle;
