import { Field, useField } from "react-final-form";

type Props = {
	name: string;
};

const HiddenInput = ({ name }: Props) => {
	const { input } = useField(name, {
		subscription: {
			value: true,
		},
		type: "hidden",
	});
	return (
		<>
			<Field component="input" type="hidden" {...input} />
		</>
	);
};

export default HiddenInput;
