import {
	createContext,
	Dispatch,
	FC,
	PropsWithChildren,
	SetStateAction,
	useContext,
	useState,
} from "react";
import FlashMessages from "../components/flashMessage/FlashMessages";

export enum FlashMessageSeverity {
	INFO = "info",
	WARNING = "warning",
	SUCCESS = "success",
	DANGER = "danger",
}

export type FlashMessageSetting = {
	message: string;
	severity: FlashMessageSeverity;
	timeout: number;
	showCross: boolean;
};

type FlashMessageType = {
	flashMessages: FlashMessageSetting[];
};

type FlashMessagesState = [
	FlashMessageType,
	Dispatch<SetStateAction<FlashMessageType>>
];
const FlashMessageContext = createContext<FlashMessagesState>(
	undefined as never
);

export const FlashMessageProvider: FC<PropsWithChildren> = ({ children }) => {
	const flashMessagesState = useState<FlashMessageType>({
		flashMessages: [],
	});
	return (
		<FlashMessageContext.Provider value={flashMessagesState}>
			<FlashMessages />
			{children}
		</FlashMessageContext.Provider>
	);
};

export const useAddFlashMessage = () => {
	const [flashMessages, setFlashMessages] = useContext(FlashMessageContext);
	return (
		newMessage: string,
		newSeverity: FlashMessageSeverity = FlashMessageSeverity.SUCCESS
	) => {
		flashMessages.flashMessages.push({
			message: newMessage,
			severity: newSeverity,
			timeout: 7000,
			showCross: true,
		});
		setFlashMessages({ ...flashMessages });
	};
};

export const getFlashMessages = (): FlashMessageType => {
	return useContext(FlashMessageContext)[0];
};
