import { FieldValidator } from "final-form";
import { Field, useField } from "react-final-form";

export type SelectOption = {
	value: string;
	label: string;
};

type Props = {
	name: string;
	options: SelectOption[];
	validate?: FieldValidator<string>;
	type?: string;
	placeholder?: string;
	label?: string;
	isRequired?: boolean;
};

const SelectInput = ({
	name,
	validate,
	placeholder,
	label,
	isRequired,
	options,
}: Props) => {
	const { input, meta } = useField(name, {
		subscription: {
			value: true,
			touched: true,
			error: true,
			submitError: true,
		},
		validate,
	});
	const hasError = (meta.touched && meta.error) || meta.submitError;
	return (
		<>
			<div className="form-control">
				{label !== undefined && (
					<label className={`${isRequired ? "required" : ""}`}>
						{label}
					</label>
				)}
				<div
					className={`form-control-input ${
						hasError ? "has-error" : ""
					}`}
				>
					<Field
						component="select"
						validate={validate}
						placeholder={placeholder}
						{...input}
					>
						{placeholder && <option value="">{placeholder}</option>}
						{options.map((x, key) => (
							<option key={key} value={x.value}>
								{x.label}
							</option>
						))}
					</Field>
					<span className="input-icons">
						<span className="exc">
							<i className="fa-solid fa-circle-exclamation"></i>
						</span>
					</span>
				</div>
				<span className="error-message">
					{hasError && (
						<span>
							{meta.error}
							{!meta.error && meta.submitError}
						</span>
					)}
				</span>
			</div>
		</>
	);
};

export default SelectInput;
