import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";

import { useForgottenPasswordForm } from "../hooks/useForgottenPasswordForm";

const ForgottenPasswordForm = () => {
	const onSubmit = useForgottenPasswordForm();
	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="h1">Obnovení účtu</div>
								<div className="text">
									Zadejte prosím e-mailovou adresu pro zaslání
									nového hesla
								</div>
								<TextInput
									name="email"
									label="E-mail"
									isRequired
									validate={required}
								></TextInput>
								<button
									type="submit"
									className="btn btn-primary btn-big align-self-center cursor-pointer"
								>
									Zaslat nové heslo
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default ForgottenPasswordForm;
