import { useState, useCallback, useEffect } from "react";
import { useGetCategories } from "../apiCalls/ApiCalls";
import { SelectOption } from "../forms/components/SelectInput";

export const useCategoryOptions = () => {
	const [categories, setCategories] = useState<SelectOption[]>([]);
	const getCategoriesApiCall = useGetCategories();
	const load = useCallback(async () => {
		const res = await getCategoriesApiCall.send();
		setCategories(
			res?.data?.map((x) => ({ value: x.id, label: x.name })) ?? []
		);
	}, []);
	useEffect(() => {
		load();
	}, []);
	return categories;
};
