import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchApiCall } from "../apiCalls/ApiCalls";
import { SearchQuery, SearchResult } from "../utils/Types";
import useSearchQuery from "./useSearchQuery";
import $ from "jquery";

const PAGE_SIZE = 5;

export const useSearchResults = () => {
	const [searchQuery] = useSearchQuery();
	const searchApiCall = useSearchApiCall();
	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
	const [page, setPage] = useState<number>(0);

	const load = useCallback(
		(searchQuery: SearchQuery | undefined, page: number) => {
			//call api
			if (searchQuery) {
				searchQuery.take = PAGE_SIZE;
				searchQuery.skip = PAGE_SIZE * page;
				searchApiCall.sendAsync(searchQuery);
			}
		},
		[]
	);

	useEffect(() => {
		//reset
		setSearchResults([]);
		setPage(0);
		load(searchQuery, 0);
	}, [searchQuery]);

	useEffect(() => {
		//append results
		if (!searchApiCall.loading) {
			const joinedSearchResults = searchResults.concat(
				searchApiCall.data?.results ?? []
			);
			setSearchResults(joinedSearchResults);
			$("#search-bar-loading").addClass("d-none");
		} else {
			$("#search-bar-loading").removeClass("d-none");
		}
	}, [searchApiCall.loading]);

	const showMoreButton = useMemo(() => {
		if (!searchApiCall.loading && searchApiCall.ok) {
			return searchApiCall.data?.totalCount !== searchResults.length;
		}
		return false;
	}, [searchResults]);

	const loadMore = () => {
		setPage(page + 1);
		load(searchQuery, page + 1);
	};
	return [searchApiCall, searchResults, showMoreButton, loadMore] as const;
};
