import { FC, useCallback } from "react";
import { useDeleteChildApiCall } from "../../apiCalls/ApiCalls";
import { useAddFlashMessage } from "../../hooks/useFlashMessage";
import { PageHelper } from "../../utils/PageHelper";
import { ChildInfo } from "../../utils/Types";

type Props = {
	childInfo: ChildInfo;
	onBack?: () => void;
	afterDelete: () => void;
};

const ChildDelete: FC<Props> = ({ childInfo, onBack, afterDelete }) => {
	const apiCall = useDeleteChildApiCall();
	const flashMessage = useAddFlashMessage();
	const onDelete = useCallback(async () => {
		await apiCall.send(undefined, { id: childInfo.id });
		flashMessage("Záznam odebrán");
		PageHelper.scrollUp();
		afterDelete();
	}, []);
	return (
		<div className="container-box-container">
			<div className="container-box container-box-dark">
				<div className="h3">
					Opravdu si přejete záznam dítěte{" "}
					<span className="no-wrap">({childInfo.name})</span> odebrat?
				</div>
				<div className="flex-end pt-2">
					<button
						className="btn btn-secondary cursor-pointer"
						type="button"
						onClick={onBack}
					>
						Zpět
					</button>
					<button
						className="btn btn-delete ml-4 cursor-pointer"
						type="button"
						onClick={onDelete}
					>
						Ano, smazat
					</button>
				</div>
			</div>
		</div>
	);
};

export default ChildDelete;
