import { useState, useEffect, useCallback } from "react";
import { useGetUserPageApiCall } from "../apiCalls/ApiCalls";
import { UserPage } from "../utils/Types";
import { useLoggedInUser } from "./useLoggedInUser";

export const useUserPage = (id: string) => {
	const userPageApiCall = useGetUserPageApiCall();
	const [userPage, setUserPage] = useState<UserPage>();
	const load = useCallback(() => {
		userPageApiCall.sendAsync(undefined, { id: id });
	}, [id]);
	useEffect(() => {
		load();
	}, [id]);
	useEffect(() => {
		if (!userPageApiCall.loading) {
			setUserPage(userPageApiCall.data);
		}
	}, [userPageApiCall.loading]);
	const [user] = useLoggedInUser();
	const editEnabled = user.isAdmin;
	return [userPage, userPageApiCall.loading, load, editEnabled] as const;
};
