import { FC } from "react";

import { Form } from "react-final-form";

import arrayMutators from "final-form-arrays";
import ChildFormFields from "../components/child/ChildFormFields";
import { useChildren, useGetNewChild } from "../hooks/useChildren";
import { FieldArray } from "react-final-form-arrays";
import { useChildrenForm } from "../hooks/useChildrenForm";

type Props = {
	parentId: string;
	afterSubmit?: () => void;
	onBack: () => void;
};

const ChildrenForm: FC<Props> = ({ afterSubmit, parentId, onBack }) => {
	const [children] = useChildren(parentId, true);
	const getNewChild = useGetNewChild(parentId);
	const [onSubmit, classes, focusOnErrors] = useChildrenForm(afterSubmit);

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{ children: children }}
			mutators={{
				...arrayMutators,
				setValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			decorators={[focusOnErrors]}
			render={({
				handleSubmit,
				form: {
					mutators: { setValue },
				},
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<FieldArray name="children">
							{({ fields }) => (
								<>
									{fields.map((child, index) => (
										<ChildFormFields
											key={index}
											showSaveButton={false}
											classes={classes}
											setValue={setValue}
											prefix={`${child}.`}
											onDelete={() => {
												fields.remove(index);
											}}
										/>
									))}
									<div
										className="container-box-container"
										onClick={() =>
											fields.push(getNewChild())
										}
									>
										<div className="container-dashed">
											<i className="upload-icon fa-solid fa-plus fa-lg"></i>
											<span className="upload-text">
												Přidat dítě
											</span>
										</div>
									</div>
								</>
							)}
						</FieldArray>

						<div className="container-box-container">
							<div className="flex-space-between w-100">
								<button
									type="button"
									className="btn btn-secondary btn-big cursor-pointer"
									onClick={onBack}
								>
									Zpět
								</button>
								<button
									type="submit"
									className="btn btn-primary btn-big cursor-pointer"
								>
									Pokračovat
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default ChildrenForm;
