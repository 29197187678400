import { FC } from "react";
import { FieldArray } from "react-final-form-arrays";
import { Category } from "../../utils/Types";

type Props = {
	categories: Category[];
};

const ActiveFilters: FC<Props> = ({ categories }) => {
	return (
		<>
			<div className="badges-container filter">
				<FieldArray name="categories">
					{({ fields }) => (
						<>
							{fields.map((badge, index) => (
								<div key={index} className="badge">
									<span className="badge-text">
										{
											categories.filter(
												(x) =>
													x.id === fields.value[index]
											)[0]?.name
										}
									</span>
									<i
										className="cross fa-solid fa-xmark"
										onClick={() => {
											fields.remove(index);
										}}
									></i>
								</div>
							))}
						</>
					)}
				</FieldArray>
			</div>
		</>
	);
};

export default ActiveFilters;
