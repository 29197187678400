import "./App.scss";
import { UserProvider } from "./hooks/useLoggedInUser";
import { BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import ScrollToTop from "./components/layout/ScrollToTop";

const App = () => (
	<UserProvider>
		<BrowserRouter>
			<ScrollToTop />
			<Layout />
		</BrowserRouter>
	</UserProvider>
);

export default App;
