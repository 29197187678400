import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmUserApiCall } from "../apiCalls/ApiCalls";
import ChildrenStep from "../components/welcome/ChildrenStep";
import InfoStep from "../components/welcome/InfoStep";
import ProfileStep from "../components/welcome/ProfileStep";
import { PageUrls } from "../constants/PageUrls";
import {
	FlashMessageSeverity,
	useAddFlashMessage,
} from "../hooks/useFlashMessage";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import usePageTitle from "../hooks/usePageTitle";
import { PageHelper } from "../utils/PageHelper";

enum Step {
	STEP_INFO,
	STEP_PROFILE,
	STEP_CHILDREN,
}

const Welcome = () => {
	const apiCall = useConfirmUserApiCall();
	const [user, setUser] = useLoggedInUser();
	const [step, setStep] = useState<Step>(Step.STEP_INFO);
	const flashMessage = useAddFlashMessage();
	const navigate = useNavigate();
	const onDone = useCallback(async () => {
		const res = await apiCall.send(undefined, { id: user.id });
		if (res?.ok) {
			user.isConfirmed = true;
			setUser(user);
			flashMessage("Nyní je vše nastaveno.");
			navigate(PageUrls.SEARCH[0]);
		} else {
			flashMessage("Došlo k chybě.", FlashMessageSeverity.DANGER);
		}
	}, []);
	usePageTitle("Vítejte");
	return (
		<>
			{step === Step.STEP_INFO && (
				<InfoStep onNext={() => setStep(Step.STEP_PROFILE)} />
			)}
			{step == Step.STEP_PROFILE && (
				<ProfileStep
					onBack={() => {
						PageHelper.scrollUp();
						setStep(Step.STEP_INFO);
					}}
					onNext={() => {
						PageHelper.scrollUp();
						setStep(Step.STEP_CHILDREN);
					}}
				/>
			)}
			{step == Step.STEP_CHILDREN && (
				<ChildrenStep
					onBack={() => {
						PageHelper.scrollUp();
						setStep(Step.STEP_PROFILE);
					}}
					onNext={onDone}
				/>
			)}
		</>
	);
};

export default Welcome;
