import { useCallback } from "react";

export const useFilter = (
	setValue: (fieldName: string, value: object) => void
) => {
	const onDropdownClick = useCallback((e: React.MouseEvent) => {
		const filterElement = e.currentTarget.parentElement;
		if (filterElement?.classList.contains("active")) {
			filterElement.classList.remove("active");
		} else {
			filterElement?.classList.add("active");
		}
	}, []);

	const onFilterReset = useCallback((name: string) => {
		setValue(name, []);
	}, []);
	return [onDropdownClick, onFilterReset] as const;
};
