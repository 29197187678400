const Unloaded = () => {
	return (
		<>
			<div className="container-group">
				<div className="profile-image unloaded"></div>

				<div className="header-unloaded"></div>
			</div>
			<div className="container-group">
				<div className="container-box-container">
					<div className="container-box container-box-dark">
						<div className="info-grid unloaded">
							<div className="info-grid-group">
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
							</div>
							<div className="info-grid-group">
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
								<div className="info-grid-row"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Unloaded;
