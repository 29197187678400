import ChildrenList from "../components/child/ChildrenList";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import usePageTitle from "../hooks/usePageTitle";

const Children = () => {
	const [user] = useLoggedInUser();
	const id = user?.id ?? "";
	usePageTitle("Vaše dítě");
	return (
		<>
			<div className="h1">Karta vašeho dítěte/dětí</div>
			<div className="container-group">
				<ChildrenList parentId={id} editEnabled={true} />
			</div>
		</>
	);
};

export default Children;
