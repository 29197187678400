import CategoriesForm from "../forms/CategoriesForm";
import { useCategoriesManage } from "../hooks/useCategoriesManage";

const Categories = () => {
	const [customCategories, baseCategories, afterSubmit] =
		useCategoriesManage();
	return (
		<>
			<div className="h1">Správa oborů</div>

			<CategoriesForm
				categories={customCategories}
				afterSubmit={afterSubmit}
			/>

			<div className="container-box-container">
				<div className="manage-list disabled">
					{baseCategories.map((x, key) => (
						<div key={key} className="manage-row">
							{x.name}
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Categories;
