import { FC } from "react";

import { Form } from "react-final-form";

import { Category } from "../utils/Types";
import BadgesInput from "./components/BadgesInput";
import arrayMutators from "final-form-arrays";
import { useCategoriesForm } from "../hooks/useCategoriesForm";

type Props = {
	categories: Category[];
	afterSubmit: () => void;
};

const CategoriesForm: FC<Props> = ({ categories, afterSubmit }) => {
	const [onSubmit, customCategories, changed, setChanged] = useCategoriesForm(
		afterSubmit,
		categories
	);
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{
				customCategories: customCategories,
			}}
			mutators={{
				...arrayMutators,
				setValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			render={({
				handleSubmit,
				form,
				form: {
					mutators: { setValue },
				},
			}) => {
				return (
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="container-box-container">
							<div className="container-box container-box-nobox">
								<BadgesInput
									setValue={setValue}
									name="customCategories"
									label="Přidané obory"
									placeholder="Přidejte obor"
									onChange={() => setChanged(true)}
								></BadgesInput>
								{changed && (
									<div className="flex-space-between w-100">
										<button
											type="button"
											className="btn btn-secondary cursor-pointer"
											onClick={() => {
												form.reset();
												setChanged(false);
											}}
										>
											Zrušit
										</button>
										<button
											type="button"
											onClick={handleSubmit}
											className="btn btn-primary cursor-pointer"
										>
											Uložit změny
										</button>
									</div>
								)}
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default CategoriesForm;
