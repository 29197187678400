import { FC } from "react";

import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import { PageUrls } from "../constants/PageUrls";
import { useCreateUserForm } from "../hooks/useCreateUserForm";
import CheckboxInput from "./components/CheckboxInput";

const CreateUserForm: FC = () => {
	const [onSubmit, focusOnErrors, navigate] = useCreateUserForm();
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{ sendEmail: true }}
			decorators={[focusOnErrors]}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-group">
							<div className="container-box-container">
								<div className="container-box container-box-main">
									<div className="flex-space-between">
										<div className="h2">Nový uživatel</div>
									</div>
									<TextInput
										name="name"
										label="Jméno"
										isRequired
										validate={required}
									></TextInput>
									<TextInput
										name="email"
										label="E-mail"
										isRequired
										validate={required}
									></TextInput>
									<CheckboxInput
										name="isAdmin"
										label="Je správce"
									/>
									<CheckboxInput
										name="sendEmail"
										label="Zaslat e-mail s heslem"
									/>
								</div>
							</div>
							<div className="flex-space-between w-100">
								<button
									type="button"
									className="btn btn-secondary btn-big cursor-pointer"
									onClick={() => {
										navigate(PageUrls.USERS[0]);
									}}
								>
									Zrušit
								</button>
								<button
									type="submit"
									className="btn btn-primary btn-big cursor-pointer"
								>
									Uložit
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default CreateUserForm;
