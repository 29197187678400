import createDecorator from "final-form-focus";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUserApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import { FormHelper } from "../utils/FormHelper";
import { useAddFlashMessage } from "./useFlashMessage";

export const useCreateUserForm = () => {
	const focusOnErrors = useMemo(() => createDecorator(), []);
	const apiCall = useCreateUserApiCall();
	const flashMessage = useAddFlashMessage();
	const navigate = useNavigate();
	const onSubmit = useCallback(async (values: object) => {
		const res = await apiCall.send(values);
		if (res?.ok && res.data) {
			flashMessage("Uživatel byl vytvořen");
			navigate(PageUrls.USER_PAGE[0].replace(":id", res.data.toString()));
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	return [onSubmit, focusOnErrors, navigate] as const;
};
