import { FC } from "react";
import { useImage } from "../../hooks/useImage";

type Props = {
	name?: string;
	imageId?: string;
	isHorizontal: boolean;
};

const UserPageHeader: FC<Props> = ({ name, imageId, isHorizontal }) => {
	const img = useImage(imageId, 500);
	return (
		<div className="container-group">
			{imageId && (
				<div className="profile-image">
					<img
						src={img}
						className={isHorizontal ? "horizontal" : "vertical"}
					/>
				</div>
			)}
			<div className="h1">{name}</div>
		</div>
	);
};

export default UserPageHeader;
