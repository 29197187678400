import { FC } from "react";
import { Field } from "react-final-form";
import { Category } from "../../utils/Types";

type Props = {
	onDropdownClick: (e: React.MouseEvent) => void;
	onFilterReset: (name: string) => void;
	categories: Category[];
};

const CategoryFilter: FC<Props> = ({
	onDropdownClick,
	onFilterReset,
	categories,
}) => {
	return (
		<div className="filter">
			<button
				type="button"
				className="btn btn-void btn-min filter-name"
				onClick={onDropdownClick}
			>
				Obor
				<span className="arrow"></span>
			</button>
			<div className="filter-list-container">
				<div className="filter-list">
					<div className="filter-list-checkboxes">
						<div className="filter-list-checkbox">
							{categories.map((x, key) => (
								<div
									key={key}
									className="form-control checkbox"
								>
									<div className="form-control-checkbox">
										<Field
											component="input"
											id={`checkbox-${key}`}
											type="checkbox"
											value={x.id}
											name="categories"
										/>
									</div>
									<label htmlFor={`checkbox-${key}`}>
										{x.name}
									</label>
								</div>
							))}
						</div>
					</div>

					<div className="filter-list-footer">
						<button
							type="button"
							className="btn btn-void btn-min dark-gray reset-filter cursor-pointer"
							onClick={() => onFilterReset("categories")}
						>
							Zrušit
						</button>
						<button
							type="submit"
							className="btn btn-void btn-min primary cursor-pointer"
						>
							Potvrdit
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryFilter;
