import { Link } from "react-router-dom";

const AloneLogo = () => (
	<div className="alone-logo">
		<Link to="/">
			<div className="navigation-logo alone"></div>
		</Link>
	</div>
);

export default AloneLogo;
