import { FC } from "react";
import { useFilters } from "../../hooks/useFilters";
import ActiveFilters from "./ActiveFilters";
import CategoryFilter from "./CategoryFilter";

type Props = {
	setValue: (fieldName: string, value: object | boolean) => void;
};

const Filters: FC<Props> = ({ setValue }) => {
	const [categories, onDropdownClick, onFilterReset, onRemoveFilterClick] =
		useFilters(setValue);
	return (
		<>
			<div className="flex-space-between filter-container">
				<div className="filters">
					<CategoryFilter
						onDropdownClick={onDropdownClick}
						onFilterReset={onFilterReset}
						categories={categories}
					/>
				</div>
				<button
					className="error btn btn-void btn-min text-button-sm cursor-pointer"
					onClick={onRemoveFilterClick}
				>
					Odstranit filtr
				</button>
			</div>
			<ActiveFilters categories={categories} />
		</>
	);
};

export default Filters;
