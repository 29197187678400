import { useState, useCallback, useEffect } from "react";
import { loadImageFromApi } from "../apiCalls/ApiCalls";

export const useImage = (imageId?: string, maxSize?: number) => {
	maxSize = maxSize ?? 200;
	const [img, setImg] = useState<string>();
	const loadImage = useCallback(async (imageId?: string) => {
		if (imageId) {
			const response = await loadImageFromApi(imageId, maxSize);
			if (response) {
				const blob = await response.blob();
				setImg(URL.createObjectURL(blob));
			}
		}
	}, []);
	useEffect(() => {
		loadImage(imageId);
	}, [imageId]);
	return img;
};
