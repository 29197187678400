import { FC } from "react";
import { Link } from "react-router-dom";
import { PageUrls } from "../../constants/PageUrls";
import { SearchResult } from "../../utils/Types";
import BadgesContainer from "../common/BadgesContainer";

type Props = {
	searchResult: SearchResult;
};

const SearchResultRecord: FC<Props> = ({ searchResult }) => (
	<Link
		to={PageUrls.USER_PAGE_LINK(searchResult.id)}
		className="container-box-container"
	>
		<div className="container-box container-box-main">
			<div className="search-result-title">
				<div className="h2">{searchResult.name}</div>
				{searchResult.category && searchResult.category.length > 0 && (
					<div className="subtitle">{searchResult.category}</div>
				)}
			</div>
			{searchResult.interests.length > 0 && (
				<BadgesContainer badges={searchResult.interests} />
			)}
		</div>
	</Link>
);

export default SearchResultRecord;
