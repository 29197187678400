import { useState, useEffect } from "react";
import { useGetUsers } from "../apiCalls/ApiCalls";
import { UserRecord } from "../utils/Types";

export const useUsers = () => {
	const [users, setUsers] = useState<UserRecord[]>([]);
	const getUsersApiCall = useGetUsers();
	useEffect(() => {
		if (!getUsersApiCall.loading && getUsersApiCall.ok) {
			setUsers(getUsersApiCall?.data ?? []);
		}
	}, [getUsersApiCall.loading]);
	useEffect(() => {
		getUsersApiCall.sendAsync();
	}, []);
	return users;
};
