import usePageTitle from "../hooks/usePageTitle";

const NotFound = () => {
	usePageTitle("NotFound");
	return (
		<div className="container-box-container">
			<div className="container-box container-box-main">
				<div className="h2">404</div>
				<div className="text">Stránka nenalezena</div>
			</div>
		</div>
	);
};

export default NotFound;
