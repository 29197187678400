import { FC } from "react";
import PostComponent from "./PostComponent";
import { Link } from "react-router-dom";
import { PageUrls } from "../../constants/PageUrls";
import { usePosts } from "../../hooks/usePosts";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

type Props = {
	userId?: string;
	editEnabled?: boolean;
	isPreview?: boolean;
};

const Posts: FC<Props> = ({ userId, editEnabled, isPreview }) => {
	const [user] = useLoggedInUser();
	const [posts] = usePosts(userId);
	return (
		<>
			<div className="board-notes-container">
				<div className={`board-notes ${!isPreview ? "own-notes" : ""}`}>
					{editEnabled && userId && (
						<Link
							to={
								userId && userId !== user.id
									? PageUrls.POST_NEW_LINK(userId)
									: PageUrls.NEW_POST[0]
							}
							className="container-box-container"
						>
							<div className="container-dashed">
								<i className="upload-icon fa-solid fa-plus fa-lg"></i>
								<span className="upload-text">
									Přidat příspěvek
								</span>
							</div>
						</Link>
					)}
					{posts.map((post, key) => (
						<PostComponent
							key={key}
							post={post}
							editEnabled={editEnabled}
							isPreview={isPreview}
							showAuthor={!userId}
						/>
					))}
				</div>
			</div>
			{false && isPreview && (
				<button className="btn btn-void btn-min show-next">
					Zobrazit další
					<i className="fa-solid fa-angle-down fa-lg"></i>
				</button>
			)}
		</>
	);
};

export default Posts;
