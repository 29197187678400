import { FC } from "react";

type Props = {
	badges: string[];
};

const BadgesContainer: FC<Props> = ({ badges }) => (
	<div className="badges-container">
		{badges.map((x, key) => (
			<div className="badge" key={key}>
				<span className="badge-text">{x}</span>
			</div>
		))}
	</div>
);
export default BadgesContainer;
