import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import HiddenInput from "./components/HiddenInput";
import { useForgottenPasswordResetForm } from "../hooks/useForgottenPasswordResetForm";
import { FC } from "react";

type Props = {
	hash: string;
};

const ForgottenPasswordResetForm: FC<Props> = ({ hash }) => {
	const onSubmit = useForgottenPasswordResetForm();
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{ Hash: hash }}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="h1">Obnovení účtu</div>
								<HiddenInput name="Hash" />
								<TextInput
									name="password"
									label="Heslo"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<TextInput
									name="confirmPassword"
									label="Heslo znovu"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<button
									type="submit"
									className="btn btn-primary btn-big align-self-center cursor-pointer"
								>
									Změnit heslo
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default ForgottenPasswordResetForm;
