import { Link } from "react-router-dom";

import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import CheckboxInput from "./components/CheckboxInput";
import { PageUrls } from "../constants/PageUrls";
import { useLoginForm } from "../hooks/useLoginForm";

const LoginForm = () => {
	const onSubmit = useLoginForm();

	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="h1">Přihlášení</div>
								<TextInput
									name="email"
									label="E-mail"
									isRequired
									validate={required}
								></TextInput>
								<TextInput
									name="password"
									label="Heslo"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<div className="flex-space-between">
									<CheckboxInput
										name="rememberMe"
										label="Zapamatovat"
										id="remember"
									/>
									<span>
										<Link
											to={PageUrls.FORGOTTEN_PASSWORD[0]}
											className="text-button"
										>
											Zapomněli jste heslo?
										</Link>
									</span>
								</div>
								<button
									type="submit"
									className="btn btn-primary btn-big align-self-center cursor-pointer"
								>
									Přihlásit se
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

{
	/* <Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<h2>Login</h2>
					<div>
						<label>First Name</label>
						<TextInput id="email" label="login"></TextInput>
					</div>

					<button type="submit">Submit</button>
				</form>
			)}
		/> */
}

export default LoginForm;
