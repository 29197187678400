import createDecorator from "final-form-focus";
import { useCallback, useMemo } from "react";
import { usePutChildListApiCall } from "../apiCalls/ApiCalls";
import { FormHelper } from "../utils/FormHelper";
import { ChildInfo } from "../utils/Types";
import { useAddFlashMessage } from "./useFlashMessage";
import { PageHelper } from "../utils/PageHelper";

type ChildFormValues = ChildInfo & {
	interests_new: string;
};

type FormDataObject = ChildInfo & {
	children?: ChildFormValues[];
};

type ChildrenFormData = {
	children: object[];
};

export const useChildrenForm = (afterSubmit?: () => void) => {
	const apiCall = usePutChildListApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		const formValues = values as FormDataObject;

		const formData: ChildrenFormData = { children: [] };
		if (formValues.children != undefined) {
			const editChildren: object[] = [];
			for (let i = 0; i < formValues.children.length; i++) {
				editChildren[i] = manageUnsetBadges(formValues.children[i]);
			}

			formData.children = editChildren;
		}

		const res = await apiCall.send(formData);
		if (res?.ok) {
			PageHelper.scrollUp();
			flashMessage("Změny byly uloženy");
			if (afterSubmit != undefined) {
				afterSubmit();
			}
		} else {
			const errors = FormHelper.getErrorsObject(res?.errors);
			return FormHelper.parseObject(errors);
		}
	}, []);
	const classes = FormHelper.getClassNameOptions();
	const focusOnErrors = useMemo(() => createDecorator(), []);
	return [onSubmit, classes, focusOnErrors] as const;
};

const manageUnsetBadges = (values: ChildFormValues): object => {
	const interestNew = FormHelper.clearBadgeValue(values.interests_new);
	if (interestNew) {
		if (values.interests === undefined) {
			values.interests = [];
		}
		values.interests.concat(interestNew);
	}
	return values;
};
