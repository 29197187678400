import { useCallback } from "react";
import { usePasswordResetApiCall } from "../apiCalls/ApiCalls";
import { FormHelper } from "../utils/FormHelper";
import { PageHelper } from "../utils/PageHelper";
import { useAddFlashMessage } from "./useFlashMessage";

export const usePasswordResetForm = (onOk: () => void) => {
	const loginApiCallResult = usePasswordResetApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		const res = await loginApiCallResult.send(values);
		if (res?.ok) {
			PageHelper.scrollUp();
			flashMessage("Změny byly uloženy");
			onOk();
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	return onSubmit;
};
