import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsHashValidApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import ForgottenPasswordResetForm from "../forms/ForgottenPasswordResetForm";
import {
	FlashMessageSeverity,
	useAddFlashMessage,
} from "../hooks/useFlashMessage";
import usePageTitle from "../hooks/usePageTitle";

const ForgottenPasswordReset = () => {
	usePageTitle("Obnovení účtu");
	const isHashValidApiCall = useIsHashValidApiCall();
	const flashMessage = useAddFlashMessage();
	const [searchParams] = useSearchParams();
	const hash = searchParams.get("hash") ?? "";
	const navigate = useNavigate();
	const checkHash = useCallback(async () => {
		const res = await isHashValidApiCall.send({ hash: hash });
		if (res === undefined || !res.ok || !res?.data) {
			flashMessage(
				"Platnost odkazu vypršela",
				FlashMessageSeverity.WARNING
			);
			navigate(PageUrls.LOGIN[0]);
		}
	}, []);
	useEffect(() => {
		checkHash();
	}, []);
	return (
		<ForgottenPasswordResetForm hash={hash}></ForgottenPasswordResetForm>
	);
};

export default ForgottenPasswordReset;
