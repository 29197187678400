import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginApiCall } from "../apiCalls/ApiCalls";
import { PageUrls } from "../constants/PageUrls";
import { FormHelper } from "../utils/FormHelper";
import { useAddFlashMessage, FlashMessageSeverity } from "./useFlashMessage";
import { useLoggedInUser } from "./useLoggedInUser";

export const useLoginForm = () => {
	const navigate = useNavigate();
	const [, setAuth] = useLoggedInUser();
	const loginApiCall = useLoginApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		const res = await loginApiCall.send(values);
		if (res?.ok && res.data) {
			setAuth(res.data);
			flashMessage("Úspěšně přihlášen");
			navigate(PageUrls.SEARCH[0]);
		} else {
			if (res?.unauthorized) {
				flashMessage(
					"Přihlášení se nezdařilo",
					FlashMessageSeverity.WARNING
				);
			} else {
				return FormHelper.getErrorsObject(res?.errors);
			}
		}
	}, []);
	return onSubmit;
};
