import createDecorator from "final-form-focus";
import { useCallback, useMemo } from "react";
import { usePutUserPageApiCall } from "../apiCalls/ApiCalls";
import { FormHelper } from "../utils/FormHelper";
import { PageHelper } from "../utils/PageHelper";
import { UserInfo, UserPage } from "../utils/Types";
import { useCategoryOptions } from "./useCategoryOptions";
import { useAddFlashMessage } from "./useFlashMessage";
import { useImage } from "./useImage";

type FormDataObject = UserInfo & {
	interests_new: string;
	offering_new: string;
};

export const useUserPageForm = (
	userPage?: UserPage,
	afterSubmit?: () => void
) => {
	const img = useImage(userPage?.imageId);
	const editApiCall = usePutUserPageApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(
		async (values: object) => {
			values = manageUnsetBadges(values as FormDataObject);
			const formData = FormHelper.convertToFormData(values);
			const res = await editApiCall.send(formData, { id: userPage?.id });
			PageHelper.scrollUp();
			if (res?.ok) {
				flashMessage("Změny byly uloženy");
				if (afterSubmit) afterSubmit();
			} else {
				return FormHelper.getErrorsObject(res?.errors);
			}
		},
		[userPage]
	);
	const categories = useCategoryOptions();
	const focusOnErrors = useMemo(() => createDecorator(), []);
	return [onSubmit, categories, img, focusOnErrors] as const;
};

const manageUnsetBadges = (values: FormDataObject): object => {
	const interestNew = FormHelper.clearBadgeValue(values.interests_new);
	if (interestNew) {
		values.interests.concat(interestNew);
	}
	const offeringNew = FormHelper.clearBadgeValue(values.offering_new);
	if (offeringNew) {
		values.offering.concat(offeringNew);
	}
	return values;
};
