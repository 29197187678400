import { FC } from "react";

type Props = {
	onNext: () => void;
};

const InfoStep: FC<Props> = ({ onNext }) => {
	return (
		<div className="info-step__main-container">
			<div className="container-group">
				<div className="h1">Vítejte v LabNet</div>
				<div className="container-box-container">
					<div className="container-box container-box-nobox">
						<p>
							Děkujeme za registraci do webové databáze,
							prostřednictvím které můžete sdílet s ostatními
							rodiči čím se zabýváte, co můžete nabídnout nebo s
							čím umíte poradit.
						</p>
						<p>
							Po vyplnění vašich údajů věnujte pozornost zejména
							vyplnění vaši zájmů a know-how. Právě
							prostřednictvím těchto klíčových slov vás pak budou
							moci najít a kontaktovat další rodiče. K vašemu
							profilu si můžete také přidat vaše děti, včetně
							jejich zájmů a koníčků. Stejný způsobem pak je možné
							díky vyhledávači najít děti se stejnými koníčky a
							propojit se s nimi. Další funkcionalitou je
							nástěnka, na které si můžete vystavit nabídku
							čehokoliv, co byste chtěli nabídnout ostatním
							rodičům.
						</p>
					</div>
				</div>
				<button
					className="btn btn-primary btn-big cursor-pointer align-self-center"
					onClick={onNext}
				>
					Pokračovat
				</button>
			</div>
			<div className="container-group">
				<div className="container-box-container text-sm gray text-center">
					<p>
						Kliknutním na <i>Pokračovat</i> souhlasíte se{" "}
						<a
							href="https://labyrinthschool.cz/uploads/documents/636124215-informacni-memorandum-ke-zpracovani-osobnich-udaju.pdf"
							target="_blank"
							rel="noreferrer"
						>
							zpracováním osobních údajů
						</a>
						. Všechny dokumnety naleznete na{" "}
						<a
							href="https://labyrinthschool.cz/dokumenty"
							target="_blank"
							rel="noreferrer"
						>
							oficiálních stránkách školy
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	);
};

export default InfoStep;
