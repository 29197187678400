import { FC, useState } from "react";
import UserPageForm from "../../forms/UserPageForm";
import { PageHelper } from "../../utils/PageHelper";
import { UserPage } from "../../utils/Types";
import UserInfoComponent from "./UserInfoComponent";

type Props = {
	userInfo?: UserPage;
	editEnabled?: boolean;
	refresh: () => void;
};

enum Mode {
	VIEW,
	EDIT,
}

const UserComponent: FC<Props> = ({
	userInfo,
	refresh,
	editEnabled = false,
}) => {
	const [mode, setMode] = useState<Mode>(Mode.VIEW);
	return (
		<>
			{mode == Mode.EDIT && editEnabled && (
				<UserPageForm
					defaultValues={userInfo}
					afterSubmit={() => {
						refresh();
						setMode(Mode.VIEW);
					}}
					onBack={() => setMode(Mode.VIEW)}
				/>
			)}
			{mode == Mode.VIEW && (
				<UserInfoComponent
					userInfo={userInfo}
					editEnabled={editEnabled}
					onEdit={() => {
						PageHelper.scrollUp();
						setMode(Mode.EDIT);
					}}
				/>
			)}
		</>
	);
};

export default UserComponent;
