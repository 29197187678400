import { User } from "./Types";

const IDENTITY = "identity";

const SessionManager = {
	getUser: (): User => {
		const plainIdentity = localStorage.getItem(IDENTITY);
		if (plainIdentity === null) {
			return {
				authenticated: false,
				id: "",
				token: "",
				email: "",
				isAdmin: false,
				isConfirmed: false,
			};
		}
		const user = {
			...JSON.parse(plainIdentity),
			authenticated: true,
		} as User;
		return user;
	},

	getToken: () => {
		const plainIdentity = localStorage.getItem(IDENTITY);
		if (plainIdentity === null) {
			return undefined;
		}
		const user = JSON.parse(plainIdentity) as User;
		return user.token;
	},

	setUserSession: (user: User) => {
		localStorage.setItem(IDENTITY, JSON.stringify(user));
	},

	removeUserSession: () => {
		localStorage.removeItem(IDENTITY);
	},
};

export default SessionManager;
