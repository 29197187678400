import createDecorator from "final-form-focus";
import { useCallback, useMemo } from "react";
import { FormHelper } from "../utils/FormHelper";
import { PageHelper } from "../utils/PageHelper";
import { useCategoryOptions } from "./useCategoryOptions";
import { useAddFlashMessage } from "./useFlashMessage";
import { useImage } from "./useImage";
import { usePostPostApiCall, usePutPostApiCall } from "../apiCalls/ApiCalls";
import { PostInfo } from "../utils/Types";
import { useNavigate } from "react-router-dom";
import { PageUrls } from "../constants/PageUrls";

export const usePostForm = (post?: PostInfo, isEdit?: boolean) => {
	const img = useImage(post?.imageId);
	const flashMessage = useAddFlashMessage();
	const editApiCall = isEdit ? usePutPostApiCall() : usePostPostApiCall();
	const navigate = useNavigate();
	const onSubmit = useCallback(
		async (values: object) => {
			const params = isEdit ? { id: post?.id } : { id: post?.userId };
			const formData = FormHelper.convertToFormData(values);
			const res = await editApiCall.send(formData, params);
			PageHelper.scrollUp();
			if (res?.ok) {
				flashMessage("Změny byly uloženy");
				navigate(PageUrls.USER_PAGE_LINK(post?.userId ?? "0"));
			} else {
				return FormHelper.getErrorsObject(res?.errors);
			}
		},
		[post]
	);
	const categories = useCategoryOptions();
	const focusOnErrors = useMemo(() => createDecorator(), []);
	return [onSubmit, categories, img, focusOnErrors] as const;
};
