import { FC, useCallback } from "react";

import { Form } from "react-final-form";

import { ChildInfo } from "../utils/Types";
import arrayMutators from "final-form-arrays";
import { useChildForm } from "../hooks/useChildForm";
import ChildFormFields from "../components/child/ChildFormFields";

type Props = {
	defaultValues: ChildInfo;
	afterSubmit?: () => void;
	onBack?: () => void;
	setChildInfo?: (obj: ChildInfo) => void;
};

const ChildForm: FC<Props> = ({
	defaultValues,
	afterSubmit,
	onBack,
	setChildInfo,
}) => {
	const onOk = useCallback(async (values: object) => {
		if (setChildInfo) setChildInfo(values as ChildInfo);
		if (afterSubmit) afterSubmit();
	}, []);
	const [onSubmit, classes, focusOnErrors] = useChildForm(
		defaultValues,
		onOk
	);

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={
				defaultValues.id === 0 ? { interests: [] } : defaultValues
			}
			mutators={{
				...arrayMutators,
				setValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			decorators={[focusOnErrors]}
			render={({
				handleSubmit,
				form: {
					mutators: { setValue },
				},
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<ChildFormFields
							classes={classes}
							onBack={onBack}
							setValue={setValue}
						/>
					</form>
				);
			}}
		/>
	);
};

export default ChildForm;
