import { FC } from "react";
import { UserPage } from "../../utils/Types";
import ChildrenList from "../child/ChildrenList";

type Props = {
	editEnabled: boolean;
	userPage: UserPage;
	id: string;
};

const UserPageChildren: FC<Props> = ({ editEnabled, userPage, id }) => {
	return (
		<>
			{(!!userPage?.children.length || editEnabled) && (
				<>
					<div className="h1">Děti</div>
				</>
			)}
			<div className="container-group">
				<ChildrenList parentId={id} editEnabled={editEnabled} />
			</div>
		</>
	);
};

export default UserPageChildren;
