import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import HiddenInput from "./HiddenInput";
import $ from "jquery";
import { useCallback } from "react";

type Props = {
	name: string;
	placeholder?: string;
	label?: string;
	onChange?: () => void;
	setValue: (fieldName: string, value: string) => void;
};

const BadgesInput = ({
	name,
	placeholder,
	label,
	setValue,
	onChange,
}: Props) => {
	placeholder = placeholder ?? label ?? "";
	const newName = `${name}_new`;
	const randomId = Math.floor(Math.random() * (10000 + 1));
	const newNameInput = `input_${name}_new${randomId}`;
	const onClick = useCallback((e: React.MouseEvent) => {
		if ($(e.target).parents(".badge").length === 0) {
			const input = $(`#${newNameInput}`);
			input.trigger("focus");
		}
	}, []);
	return (
		<>
			<div className="form-control" onClick={onClick}>
				<label>{label} </label>
				<div className="form-control-badges badges-container">
					<FieldArray name={name}>
						{({ fields }) => (
							<>
								{fields.map((badge, index) => (
									<div className="badge" key={index}>
										<span className="badge-text">
											{fields.value[index]}
										</span>
										<i
											className="cross fa-solid fa-xmark"
											onClick={() => {
												fields.remove(index);
												if (onChange) onChange();
											}}
										></i>
										<HiddenInput name={`${badge}`} />
									</div>
								))}
								<Field
									id={newNameInput}
									name={newName}
									placeholder={placeholder}
									component="input"
									onBlur={(e: Event) => {
										e.preventDefault();
										if (
											e.target instanceof HTMLInputElement
										) {
											e.target.value
												.split(",")
												.forEach((x) => {
													const newVal = x
														.trimEnd()
														.trimStart();
													if (newVal.length > 0) {
														fields.push(newVal);
														setValue(newName, "");
													}
												});
										}
									}}
									onKeyUp={(e: KeyboardEvent) => {
										e.preventDefault();
										if (
											e.target instanceof HTMLInputElement
										) {
											if (onChange) onChange();
											if (e.key === ",") {
												e.target.value
													.split(",")
													.forEach((x) => {
														const newVal = x
															.trimEnd()
															.trimStart();
														if (newVal.length > 0) {
															fields.push(newVal);
															setValue(
																newName,
																""
															);
														}
													});
											}
										}
									}}
								/>
							</>
						)}
					</FieldArray>
					<span className="exc"></span>
					<span className="cross error-message"></span>
				</div>
				<span className="input-label-sm">(oddělte čárkou)</span>
			</div>
		</>
	);
};

export default BadgesInput;
