import { Link } from "react-router-dom";
import { PageUrls } from "../constants/PageUrls";
import { useUsers } from "../hooks/useUsers";
import usePageTitle from "../hooks/usePageTitle";

const Users = () => {
	usePageTitle("Uživatelé");
	const users = useUsers();
	return (
		<>
			<div className="h1">Správa uživatelů</div>
			<div className="container-box-container">
				<div className="container-box container-box-nobox">
					<Link
						to={PageUrls.USER_CREATE[0]}
						className="btn cursor-pointer btn-primary"
					>
						Nový uživatel
					</Link>
					<div className="manage-list">
						{users.map((x, key) => (
							<Link
								to={PageUrls.USER_PAGE[0].replace(":id", x.id)}
								key={key}
								className="manage-row"
							>
								{x.name} {x.isAdmin && <span> (správce)</span>}
							</Link>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Users;
