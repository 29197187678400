import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
	InternalError,
	UnauthorizedError,
	Api,
} from "../apiCalls/ApiConnector";
import { FlashMessageSeverity, useAddFlashMessage } from "./useFlashMessage";
import { useLogout } from "./useLoggedInUser";

type ApiCallResult<T> = {
	unauthorized: boolean;
	ok: boolean;
	data?: T;
	errors: object;
};

type ApiCallFactory<T> = {
	loading: boolean;
	send: (
		bodyData?: object,
		args?: object
	) => Promise<ApiCallResult<T> | undefined>;
	sendAsync: (bodyData?: object, args?: object) => void;
} & ApiCallResult<T>;

export const useApiCall = <T>(
	endpoint: string,
	method: string,
	showErrorMessage = false
): ApiCallFactory<T> => {
	const [data, setData] = useState<T>();
	const [loading, setLoading] = useState(false);
	const [ok, setOk] = useState(false);
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();
	const logout = useLogout();
	const flashMessage = useAddFlashMessage();
	const send = useCallback(async (bodyData?: object, args?: object) => {
		let ok = false;
		let data: T | undefined = undefined;
		let errors = {};
		try {
			const response = await Api(
				bindEndpointParameters(endpoint, args),
				method,
				bodyData
			);
			if (!response) {
				if (showErrorMessage) {
					flashMessage(
						"Spojení přerušeno",
						FlashMessageSeverity.DANGER
					);
				}
				return;
			}
			if (response.status === 404) {
				if (showErrorMessage) {
					flashMessage("Nenalezeno", FlashMessageSeverity.DANGER);
				}
				return;
			}
			if (response.status === 502) {
				if (showErrorMessage) {
					flashMessage(
						"Spojení přerušeno",
						FlashMessageSeverity.DANGER
					);
				}
				return;
			}
			ok = response.ok;
			if (ok) {
				data = await (response.json() as T);
			} else {
				if (response.status === 500)
					throw new InternalError(
						`Internal error (${response.status})`
					);
				if (response.status === 401) {
					logout();
					return;
				}

				if (response.status === 400) {
					const responseJson = await response.json();

					const modelErrors = responseJson ?? {};
					errors = modelErrors;
				}
			}
		} catch (err) {
			if (err instanceof InternalError) {
				console.error(err.message);
			} else if (err instanceof UnauthorizedError) {
				navigate("/");
				return;
			}
		}
		return {
			data,
			ok,
			errors,
			unauthorized: true,
		};
	}, []);

	const sendAsync = async (bodyData?: object, args?: object) => {
		setLoading(true);
		const res = await send(bodyData, args);
		setOk(res?.ok ?? false);
		if (res?.ok) {
			setData(res.data);
		} else {
			setErrors(res?.errors ?? {});
		}
		setLoading(false);
	};

	return {
		data,
		loading,
		unauthorized: false,
		ok,
		errors,
		sendAsync,
		send,
	};
};

const bindEndpointParameters = (endpoint: string, args?: object): string => {
	if (args === undefined) {
		return endpoint;
	}
	Object.entries(args).forEach(([k, v]) => {
		endpoint = endpoint.replace(`:${k}`, v);
	});
	return endpoint;
};
