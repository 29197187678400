import { SelectOption } from "../forms/components/SelectInput";

type ParsedObject = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};
export class FormHelper {
	static convertToFormData = (values: object) => {
		const formData = new FormData();
		for (const key in values) {
			const value = values[key as keyof typeof values];
			if (value === null) continue;
			formData.append(key, value);
		}
		return formData;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static parseObject = (obj: Record<string, any>): ParsedObject => {
		const parsedObj: ParsedObject = {};

		for (const key in obj) {
			const parts = key.split(".");

			let currentObj = parsedObj;

			for (let i = 0; i < parts.length; i++) {
				const part = parts[i];
				const isArray = /^\w+\[\d+\]$/.test(part);

				if (isArray) {
					const [arrayName, indexStr] = part.split("[");
					const index = indexStr.slice(0, -1); // remove closing bracket

					//creating list name
					if (!currentObj[arrayName]) {
						currentObj[arrayName] = {};
					}

					currentObj = currentObj[arrayName];

					//creating index
					if (!currentObj[index]) {
						currentObj[index] = {};
					}

					currentObj = currentObj[index];
				} else {
					if (!currentObj[part]) {
						currentObj[part] = obj[key];
					}

					currentObj = currentObj[part];
				}
			}

			currentObj = obj[key];
		}

		return parsedObj;
	};

	static getErrorsObject = (obj?: object): object => {
		if (!obj) {
			return {};
		}
		const newObj = Object.fromEntries(
			Object.entries(obj).map(([k, v]) => {
				let newKey = k[0].toLocaleLowerCase() + k.substring(1);
				const i = k.indexOf(".");
				if (i != undefined) {
					newKey =
						newKey.substring(0, i + 1) +
						newKey[i + 1].toLocaleLowerCase() +
						newKey.substring(i + 2);
				}
				return [newKey, v];
			})
		);
		return newObj;
	};

	static clearBadgeValue = (value?: string): string[] => {
		const res =
			value
				?.split(",")
				.map((x) => x.trimEnd().trimStart())
				.filter((x) => x.length) ?? [];
		return res;
	};

	static getClassNameOptions = (): SelectOption[] => {
		const res: SelectOption[] = [];
		["A", "B", "C"].forEach((element) => {
			res.push({
				value: element,
				label: element,
			});
		});
		return res;
	};
}
