import { useParams } from "react-router-dom";
import Unloaded from "../components/userPage/Unloaded";
import UserComponent from "../components/userPage/UserComponent";
import UserDelete from "../components/userPage/UserDelete";
import UserPageHeader from "../components/userPage/UserPageHeader";
import usePageTitle from "../hooks/usePageTitle";
import { useUserPage } from "../hooks/useUserPage";
import UserPageChildren from "../components/userPage/UserPageChildren";
import UserPagePosts from "../components/userPage/UserPagePosts";

const UserPage = () => {
	const id = useParams()["id"] as string;
	const [userPage, , refresh, editEnabled] = useUserPage(id);
	usePageTitle(userPage?.name ?? "");
	return (
		<>
			{userPage ? (
				<>
					<UserPageHeader
						name={userPage.name}
						imageId={userPage.imageId}
						isHorizontal={
							userPage.imageWidth >= userPage.imageHeight
						}
					/>
					<UserComponent
						userInfo={userPage}
						editEnabled={editEnabled}
						refresh={refresh}
					/>
					<UserPageChildren
						id={id}
						userPage={userPage}
						editEnabled={editEnabled}
					/>
					<UserPagePosts
						id={id}
						userPage={userPage}
						editEnabled={editEnabled}
					/>

					{editEnabled && <UserDelete id={id} />}
				</>
			) : (
				<Unloaded />
			)}
		</>
	);
};

export default UserPage;
