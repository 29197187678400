import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { FC } from "react";
import ChildrenForm from "../../forms/ChildrenForm";

type Props = {
	onNext: () => void;
	onBack: () => void;
};

const ChildrenStep: FC<Props> = ({ onBack, onNext }) => {
	const [user] = useLoggedInUser();
	const id = user?.id ?? "";
	return (
		<>
			<div className="h1">Karta vašeho dítěte/dětí</div>
			<div className="container-group">
				<ChildrenForm
					parentId={id}
					onBack={onBack}
					afterSubmit={onNext}
				/>
			</div>
		</>
	);
};

export default ChildrenStep;
