import { FC } from "react";
import { UserPage } from "../../utils/Types";
import Posts from "../board/Posts";

type Props = {
	editEnabled: boolean;
	userPage: UserPage;
	id: string;
};

const UserPagePosts: FC<Props> = ({ editEnabled, userPage, id }) => {
	return (
		<>
			{!!userPage?.posts.length && (
				<>
					<div className="h1">Příspěvky na nástěnce</div>
				</>
			)}
			<div className="container-group">
				<Posts userId={id} editEnabled={editEnabled} />
			</div>
		</>
	);
};

export default UserPagePosts;
