import { useApiCall } from "../hooks/useFetchData";
import {
	Category,
	ChildInfo,
	Post,
	SearchResults,
	User,
	UserPage,
	UserRecord,
} from "../utils/Types";
import { Api } from "./ApiConnector";
import { ApiEndpoints } from "./ApiEndpoints";

const POST = "POST";
const GET = "GET";
const PUT = "PUT";
const DELETE = "DELETE";

export const useLoginApiCall = () =>
	useApiCall<User>(ApiEndpoints.LOGIN, POST, true);

export const useForgottenPasswordApiCall = () =>
	useApiCall<object>(ApiEndpoints.FORGOTTEN_PASSWORD, POST, true);

export const useForgottenPasswordResetApiCall = () =>
	useApiCall<object>(ApiEndpoints.FORGOTTEN_PASSWORD_RESET, POST, true);

export const useIsHashValidApiCall = () =>
	useApiCall<boolean>(ApiEndpoints.IS_HASH_VALID, POST, true);

export const usePasswordResetApiCall = () =>
	useApiCall<object>(ApiEndpoints.PASSWORD_RESET, POST, true);

export const useSearchApiCall = () =>
	useApiCall<SearchResults>(ApiEndpoints.SEARCH, POST, true);

export const useGetUserPageApiCall = () =>
	useApiCall<UserPage>(ApiEndpoints.USER_PAGE_GET, GET, true);

export const useChildrenApiCall = () =>
	useApiCall<ChildInfo[]>(ApiEndpoints.CHILDREN_GET, GET, true);

export const usePutUserPageApiCall = () =>
	useApiCall<UserPage>(ApiEndpoints.USER_PAGE_PUT, PUT, true);

export const usePostChildApiCall = () =>
	useApiCall<number>(ApiEndpoints.CHILD_POST, POST, true);

export const usePutChildApiCall = () =>
	useApiCall<ChildInfo>(ApiEndpoints.CHILD_PUT, PUT, true);

export const usePutChildListApiCall = () =>
	useApiCall<ChildInfo>(ApiEndpoints.CHILD_LIST_PUT, PUT, true);

export const useDeleteChildApiCall = () =>
	useApiCall<object>(ApiEndpoints.CHILD_DELETE, DELETE, true);

export const useDeleteUserApiCall = () =>
	useApiCall<object>(ApiEndpoints.USER_DELETE, DELETE, true);

export const useConfirmUserApiCall = () =>
	useApiCall<object>(ApiEndpoints.USER_CONFIRM, POST, true);

export const loadImageFromApi = async (id: string, maxSize?: number) =>
	await Api(
		`${ApiEndpoints.IMAGE.replace(":id", id)}${
			maxSize ? `?maxSize=${maxSize}` : ""
		}`,
		GET
	);

export const useGetUsers = () =>
	useApiCall<UserRecord[]>(ApiEndpoints.USERS_GET, GET, true);

export const useGetCategories = (showErrors = false) =>
	useApiCall<Category[]>(ApiEndpoints.CATEGORY_GET, GET, showErrors);

export const useUpdateCategoriesApiCall = () =>
	useApiCall<object>(ApiEndpoints.CATEGORIES_POST, POST, true);

export const useCreateUserApiCall = () =>
	useApiCall<number>(ApiEndpoints.USER_POST, POST, true);

export const useDeletePostApiCall = () =>
	useApiCall<object>(ApiEndpoints.POST_DELETE, DELETE, true);

export const useGetPostApiCall = () =>
	useApiCall<Post[]>(ApiEndpoints.POSTS_GET, GET, false);

export const useGetUsersPostApiCall = () =>
	useApiCall<Post[]>(ApiEndpoints.POSTS_GET_USERS, GET, false);

export const useGetPostDetailApiCall = () =>
	useApiCall<Post>(ApiEndpoints.POSTS_GET_DETAIL, GET, false);
export const usePutPostApiCall = () =>
	useApiCall<Post>(ApiEndpoints.POST_PUT, PUT, true);

export const usePostPostApiCall = () =>
	useApiCall<Post>(ApiEndpoints.POST_POST, POST, true);
