import { FC } from "react";

import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import { UserPage } from "../utils/Types";
import FileInput from "./components/FileInput";
import SelectInput from "./components/SelectInput";
import BadgesInput from "./components/BadgesInput";
import arrayMutators from "final-form-arrays";
import { useUserPageForm } from "../hooks/useUserPageForm";

type Props = {
	defaultValues?: UserPage;
	loading?: boolean;
	afterSubmit?: () => void;
	onBack?: () => void;
	backLabel?: string;
	submitLabel?: string;
};

const UserPageForm: FC<Props> = ({
	defaultValues,
	loading,
	onBack,
	afterSubmit,
	backLabel = "Zrušit",
	submitLabel = "Uložit",
}) => {
	const [onSubmit, categories, img, focusOnErrors] = useUserPageForm(
		defaultValues,
		afterSubmit
	);

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={defaultValues}
			mutators={{
				...arrayMutators,
				setValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			decorators={[focusOnErrors]}
			render={({
				handleSubmit,
				form,
				form: {
					mutators: { setValue },
				},
			}) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-group">
							<div className="container-box-container">
								<div className="container-box container-box-main">
									<div className="flex-space-between">
										<div className="h2">Kontakt</div>
										{loading && (
											<span>
												<i className="fas fa-spinner fa-lg fa-spin"></i>
											</span>
										)}
									</div>
									<TextInput
										name="name"
										label="Jméno"
										isRequired
										validate={required}
									></TextInput>
									<TextInput
										name="contactEmail"
										label="Kontaktní e-mail"
										isRequired
										validate={required}
									></TextInput>
									<TextInput
										name="phoneNumber"
										label="Telefon"
									></TextInput>
									<TextInput
										name="linkedInUrl"
										label="Linked-in"
									></TextInput>
									<TextInput
										name="residence"
										label="Bydliště (obec nebo čtvrť)"
									></TextInput>
									<FileInput
										name="imageFile"
										label="Nahrajte profilovou fotku"
										defaultPreview={img}
									/>
								</div>
							</div>

							<div className="container-box-container">
								<div className="container-box container-box-main">
									<div className="h2">Práce</div>
									<TextInput
										name="profession"
										label="Profese"
									></TextInput>
									<SelectInput
										name="categoryId"
										label="Obor"
										placeholder="Vyberte"
										options={categories}
									></SelectInput>
									<TextInput
										name="employer"
										label="Zaměstnavatel"
									></TextInput>
									<TextInput
										name="webUrl"
										label="Web"
									></TextInput>
								</div>
							</div>

							<div className="container-box-container">
								<div className="container-box container-box-main">
									<div className="h2">Ostatní</div>
									<BadgesInput
										setValue={setValue}
										name="interests"
										label="Zájmy"
									></BadgesInput>
									<BadgesInput
										setValue={setValue}
										name="offering"
										label="Co nabízím"
									></BadgesInput>
								</div>
							</div>

							<div className="flex-space-between w-100">
								<button
									type="button"
									className="btn btn-secondary btn-big cursor-pointer"
									onClick={() => {
										form.reset();
										if (onBack) onBack();
									}}
								>
									{backLabel}
								</button>
								<button
									type="submit"
									className="btn btn-primary btn-big cursor-pointer"
								>
									{submitLabel}
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default UserPageForm;
