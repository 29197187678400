import { useSearchResults } from "../../hooks/useSearchResults";
import SearchResultRecord from "./SearchResult";

const SearchResults = () => {
	const [searchApiCall, searchResults, showMoreButton, loadMore] =
		useSearchResults();
	return (
		<>
			<div className="search-results">
				{searchResults.map((x, key) => (
					<SearchResultRecord key={key} searchResult={x} />
				))}
				{searchApiCall.ok &&
					searchApiCall.loading == false &&
					searchResults.length === 0 && (
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="h2">Žádné výsledky</div>
							</div>
						</div>
					)}
				{searchApiCall.loading && (
					<>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="unloaded"></div>
							</div>
						</div>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="unloaded"></div>
							</div>
						</div>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="unloaded"></div>
							</div>
						</div>
					</>
				)}
			</div>
			{showMoreButton && (
				<button
					onClick={loadMore}
					className="btn btn-void btn-min show-next"
				>
					Zobrazit další{" "}
					{searchApiCall.data?.totalCount && (
						<>
							(
							{searchApiCall.data.totalCount -
								searchResults.length}
							)
						</>
					)}
					<i className="fa-solid fa-angle-down fa-lg"></i>
				</button>
			)}
		</>
	);
};

export default SearchResults;
