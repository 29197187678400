import { useCallback, useMemo, useState } from "react";
import { useUpdateCategoriesApiCall } from "../apiCalls/ApiCalls";
import { FormHelper } from "../utils/FormHelper";
import { PageHelper } from "../utils/PageHelper";
import { Category } from "../utils/Types";
import { useAddFlashMessage } from "./useFlashMessage";

type FormDataObject = {
	customCategories: string[];
	customCategories_new: string;
};

const manageUnsetBadges = (values: FormDataObject): object => {
	const interestNew = FormHelper.clearBadgeValue(values.customCategories_new);
	if (interestNew) {
		values.customCategories.concat(interestNew);
	}
	return values;
};

export const useCategoriesForm = (
	afterSubmit: () => void,
	categories: Category[]
) => {
	const apiCall = useUpdateCategoriesApiCall();
	const flashMessage = useAddFlashMessage();
	const onSubmit = useCallback(async (values: object) => {
		values = manageUnsetBadges(values as FormDataObject);
		const formData = values;
		const res = await apiCall.send(formData);
		if (res?.ok) {
			PageHelper.scrollUp();
			flashMessage("Změny byly uloženy");
			setChanged(false);
			afterSubmit();
		} else {
			return FormHelper.getErrorsObject(res?.errors);
		}
	}, []);
	const customCategories = useMemo(
		() => categories.map((x) => x.name),
		[categories]
	);
	const [changed, setChanged] = useState(false);
	return [onSubmit, customCategories, changed, setChanged] as const;
};
