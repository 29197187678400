import { FC } from "react";

import { Form } from "react-final-form";
import { required } from "../utils/FormValidationRules";
import TextInput from "./components/TextInput";
import { usePasswordResetForm } from "../hooks/usePasswordResetForm";

type Props = {
	afterSubmit: () => void;
	onBack: () => void;
};

const PasswordResetForm: FC<Props> = ({ afterSubmit, onBack }) => {
	const onSubmit = usePasswordResetForm(afterSubmit);
	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container-box-container">
							<div className="container-box container-box-main">
								<div className="h2">Změnit heslo</div>
								<TextInput
									name="password"
									label="Aktuální"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<TextInput
									name="newPassword"
									label="Nové"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<TextInput
									name="confirmPassword"
									label="Znovu zadejte nové"
									isRequired
									validate={required}
									type="password"
								></TextInput>
								<div className="flex-space-between w-100">
									<button
										type="button"
										className="btn btn-secondary btn-big cursor-pointer"
										onClick={onBack}
									>
										Zrušit
									</button>
									<button
										type="submit"
										className="btn btn-primary btn-big cursor-pointer"
									>
										Uložit
									</button>
								</div>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default PasswordResetForm;
