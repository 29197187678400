import { FC } from "react";

import BadgesInput from "../../forms/components/BadgesInput";
import SelectInput, { SelectOption } from "../../forms/components/SelectInput";
import TextInput from "../../forms/components/TextInput";
import { integer } from "../../utils/FormValidationRules";
import HiddenInput from "../../forms/components/HiddenInput";

type Props = {
	onBack?: () => void;
	classes: SelectOption[];
	setValue: (fieldName: string, value: string) => void;
	showSaveButton?: boolean;
	onDelete?: () => void;
	prefix?: string;
};

const ChildFormFields: FC<Props> = ({
	classes,
	onBack,
	setValue,
	showSaveButton = true,
	prefix = "",
	onDelete,
}) => {
	return (
		<div className="container-box-container">
			<div className="container-box container-box-main">
				<HiddenInput name={`${prefix}id`} />
				<TextInput
					name={`${prefix}name`}
					label="Jméno"
					isRequired
					//validate={required}
				></TextInput>
				<div className="flex-space-between w-100">
					<TextInput
						name={`${prefix}yearOfEntry`}
						label="Rok nástupu"
						isRequired
						type="number"
						isInteger={true}
						validate={integer}
					></TextInput>
					<SelectInput
						name={`${prefix}className`}
						label="Třída"
						placeholder="Vyberte"
						options={classes}
						isRequired
					></SelectInput>
				</div>
				<BadgesInput
					setValue={setValue}
					name={`${prefix}interests`}
					label="Zájmy"
					placeholder="Přidejte koníček"
				></BadgesInput>

				<div className="flex-space-between w-100">
					{onBack != undefined && (
						<button
							type="button"
							className="btn btn-secondary btn-big cursor-pointer"
							onClick={onBack}
						>
							Zrušit
						</button>
					)}
					{showSaveButton && (
						<button
							type="submit"
							className="btn btn-primary btn-big cursor-pointer"
						>
							Uložit
						</button>
					)}
					{onDelete != undefined && (
						<>
							<div></div>
							<button
								type="button"
								className="btn btn-void btn-sm error cursor-pointer"
								onClick={onDelete}
							>
								Odebrat
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ChildFormFields;
