import { useState } from "react";
import Posts from "./Posts";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

enum Mode {
	ALL,
	OWN,
}

const BoardComponent = () => {
	const [mode, setMode] = useState<Mode>(Mode.ALL);
	const [user] = useLoggedInUser();
	return (
		<>
			<div className="h1">Nástěnka</div>
			<div className="board-toggle">
				<button
					className={`btn btn-void cursor-pointer ${
						mode == Mode.ALL ? "active" : ""
					}`}
					onClick={() => setMode(Mode.ALL)}
				>
					Příspěvky
				</button>
				<button
					className={`btn btn-void cursor-pointer ${
						mode == Mode.OWN ? "active" : ""
					}`}
					onClick={() => setMode(Mode.OWN)}
				>
					Vaše příspěvky
				</button>
			</div>
			<div className={` ${mode == Mode.OWN ? "d-none" : ""} w-100`}>
				<Posts isPreview={true} />
			</div>
			<div className={` ${mode == Mode.ALL ? "d-none" : ""} w-100`}>
				<Posts editEnabled={true} userId={user.id} />
			</div>
		</>
	);
};

export default BoardComponent;
