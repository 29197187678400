import { Link, useLocation } from "react-router-dom";
import { useLoggedInUser, useLogout } from "../../hooks/useLoggedInUser";
import $ from "jquery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageUrls } from "../../constants/PageUrls";

const getSettingsPageUrls = (): string[] =>
	PageUrls.PROFILE.concat(PageUrls.CHILDREN)
		.concat(PageUrls.SECURITY)
		.concat(PageUrls.SUPPORT);

const Navigation = () => {
	const [user] = useLoggedInUser();

	const [menuActive, setMenuActive] = useState(false);

	const menuToggle = useCallback(() => {
		setMenuActive(!menuActive);
	}, [menuActive]);

	const submenuToggle = useCallback((e: React.MouseEvent) => {
		const submenu = $(e.currentTarget)
			.parent()
			.find(".navigation-submenu-container")
			.first();
		if (submenu.hasClass("active-toggle")) {
			submenu.removeClass("active-toggle");
		} else {
			submenu.addClass("active-toggle");
		}
	}, []);
	const logout = useLogout();
	const location = useLocation();

	const segment = useMemo(() => {
		const indexTo = location.pathname.indexOf("/", 1);
		const segment = location.pathname.substring(
			0,
			indexTo !== -1 ? indexTo : undefined
		);
		return segment;
	}, [location]);
	useEffect(() => {
		setMenuActive(false);
	}, [segment]);
	const isMenuItemActive = (pageUrls: string[]): boolean => {
		return pageUrls.some((x) => segment == x);
	};

	return (
		<>
			{user.authenticated && (
				<nav className="navigation">
					<div className="strip strip-sm-md" id="strip"></div>
					<div className="navigation-header">
						<Link to="/" className="navigation-item">
							<div className="navigation-logo"></div>
						</Link>
						<div
							className="bars cursor-pointer"
							onClick={menuToggle}
						>
							<i className="fa-solid fa-bars fa-lg"></i>
						</div>
					</div>
					<div
						className={`navigation-main ${
							menuActive ? "active" : ""
						}
							${!user.isConfirmed ? "justify-content-end" : ""}`}
					>
						{user.isConfirmed && (
							<div className="navigation-menu">
								<Link
									to={PageUrls.SEARCH[0]}
									className={`navigation-item-container ${
										isMenuItemActive(PageUrls.SEARCH)
											? "active"
											: ""
									}`}
								>
									<div className="navigation-item">
										<span className="icon">
											<i className="fa-solid fa-magnifying-glass fa-lg"></i>
										</span>
										<span className="navigation-item-text">
											Vyhledávání
										</span>
									</div>
								</Link>
								{user.isAdmin && (
									<>
										<Link
											to={PageUrls.USERS[0]}
											className={`navigation-item-container ${
												isMenuItemActive(PageUrls.USERS)
													? "active"
													: ""
											}`}
										>
											<div className="navigation-item">
												<span className="icon">
													<i className="fa-solid fa-users fa-lg"></i>
												</span>
												<span className="navigation-item-text">
													Uživatelé
												</span>
											</div>
										</Link>
										<Link
											to={PageUrls.POSTS[0]}
											className={`navigation-item-container ${
												isMenuItemActive(PageUrls.POSTS)
													? "active"
													: ""
											}`}
										>
											<div className="navigation-item">
												<span className="icon">
													<i className="fa-solid fa-note-sticky fa-lg"></i>
												</span>
												<span className="navigation-item-text">
													Nástěnka
												</span>
											</div>
										</Link>
										<Link
											to={PageUrls.CATEGORIES[0]}
											className={`navigation-item-container ${
												isMenuItemActive(
													PageUrls.CATEGORIES
												)
													? "active"
													: ""
											}`}
										>
											<div className="navigation-item">
												<span className="icon">
													<i className="fa-solid fa-list fa-lg"></i>
												</span>
												<span className="navigation-item-text">
													Obory
												</span>
											</div>
										</Link>
									</>
								)}
								<div className="navigation-menu-item-container">
									<div
										className={`navigation-item-container cursor-pointer ${
											isMenuItemActive(
												getSettingsPageUrls()
											)
												? "active"
												: ""
										}`}
										onClick={(e) => submenuToggle(e)}
									>
										<div className="navigation-item">
											<span className="icon">
												<i className="fa-solid fa-user-pen fa-lg"></i>
											</span>
											<span className="navigation-item-text">
												Nastavení
											</span>
										</div>
									</div>
									<div
										className={`navigation-submenu-container ${
											isMenuItemActive(
												getSettingsPageUrls()
											)
												? "active"
												: ""
										}`}
									>
										<Link
											to={PageUrls.PROFILE[0]}
											className={`navigation-item submenu ${
												isMenuItemActive(
													PageUrls.PROFILE
												)
													? "active"
													: ""
											}`}
										>
											<span className="icon"></span>
											<span className="navigation-item-text">
												Osobní údaje
											</span>
										</Link>
										<Link
											to={PageUrls.CHILDREN[0]}
											className={`navigation-item submenu ${
												isMenuItemActive(
													PageUrls.CHILDREN
												)
													? "active"
													: ""
											}`}
										>
											<span className="icon"></span>
											<span className="navigation-item-text">
												Vaše dítě
											</span>
										</Link>
										<Link
											to={PageUrls.SECURITY[0]}
											className={`navigation-item submenu ${
												isMenuItemActive(
													PageUrls.SECURITY
												)
													? "active"
													: ""
											}`}
										>
											<span className="icon"></span>
											<span className="navigation-item-text">
												Zabezpečení
											</span>
										</Link>
										{!user.isAdmin && (
											<Link
												to={PageUrls.SUPPORT[0]}
												className={`navigation-item submenu ${
													isMenuItemActive(
														PageUrls.SUPPORT
													)
														? "active"
														: ""
												}`}
											>
												<span className="icon"></span>
												<span className="navigation-item-text">
													Podpora
												</span>
											</Link>
										)}
									</div>
								</div>
							</div>
						)}
						<button
							type="button"
							onClick={logout}
							className="navigation-item-container btn btn-void cursor-pointer"
						>
							<div className="navigation-item logout">
								<span className="icon">
									<i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i>
								</span>
								<span className="navigation-item-text">
									Odhlásit se
								</span>
							</div>
						</button>
					</div>
				</nav>
			)}
		</>
	);
};

export default Navigation;
