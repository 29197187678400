import { FC, useState } from "react";
import { useUserDelete } from "../../hooks/useUserDelete";

type Props = {
	id: string;
};

enum Mode {
	FIRST,
	CONFIRM,
}

const UserDelete: FC<Props> = ({ id }) => {
	const onDelete = useUserDelete(id);
	const [mode, setMode] = useState<Mode>(Mode.FIRST);
	return (
		<div className="container-group">
			{mode == Mode.FIRST && (
				<button
					className="btn btn-delete cursor-pointer"
					onClick={() => setMode(Mode.CONFIRM)}
				>
					Smazat uživatele
				</button>
			)}
			{mode == Mode.CONFIRM && (
				<div className="container-box-container">
					<div className="container-box container-box-dark">
						<div className="h3">
							Opravdu si přejete uživatele nenávratně smazat?
						</div>
						<div className="flex-end pt-2">
							<button
								className="btn btn-secondary cursor-pointer"
								type="button"
								onClick={() => setMode(Mode.FIRST)}
							>
								Zpět
							</button>
							<button
								className="btn btn-delete ml-4 cursor-pointer"
								type="button"
								onClick={onDelete}
							>
								Ano, smazat
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserDelete;
