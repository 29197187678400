import { FieldValidator } from "final-form";
import { Field, SupportedInputs, useField } from "react-final-form";
import PasswordEyeToggle from "./PasswordEyeToggle";
import $ from "jquery";
import { useCallback } from "react";

type Props = {
	name: string;
	validate?: FieldValidator<string>;
	type?: string;
	placeholder?: string;
	label?: string;
	isRequired?: boolean;
	isInteger?: boolean;
	component?: SupportedInputs;
	rows?: number;
};

const TextInput = ({
	name,
	validate,
	type,
	placeholder,
	label,
	isRequired,
	isInteger,
	component = "input",
	rows,
}: Props) => {
	const { input, meta } = useField(name, {
		subscription: {
			value: true,
			touched: true,
			error: true,
			submitError: true,
		},
		validate,
	});
	if (isInteger) {
		input.step = 1;
		input.min = 0;
	}
	if (component == "textarea") {
		input.rows = rows;
	}
	const hasError = (meta.touched && meta.error) || meta.submitError;
	placeholder = placeholder ?? "";
	const onClick = useCallback((e: React.MouseEvent) => {
		const input = $(e.currentTarget).find("input");
		input.trigger("focus");
	}, []);
	return (
		<>
			<div className="form-control" onClick={onClick}>
				{label !== undefined && (
					<label className={`${isRequired ? "required" : ""}`}>
						{label}
					</label>
				)}
				<div
					className={`form-control-input ${
						hasError ? "has-error" : ""
					}`}
				>
					<Field
						id={`input_${name}`}
						component={component}
						validate={validate}
						placeholder={placeholder}
						type={type}
						{...input}
					/>
					<span className="input-icons">
						<span className="exc">
							<i className="fa-solid fa-circle-exclamation"></i>
						</span>
						{type === "password" && <PasswordEyeToggle />}
					</span>
				</div>
				<span className="error-message">
					{hasError && (
						<span>
							{meta.error}
							{!meta.error && meta.submitError}
						</span>
					)}
				</span>
			</div>
		</>
	);
};

export default TextInput;
